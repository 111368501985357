import { combineReducers } from 'redux';
import loanOfficer from './loanOfficer';
import tasks from './tasks';
import messages from './messages';
import loanNew from 'src/components/Loan/Application/store/loan/reducer';
import fields from 'src/components/Loan/Application/store/fields/reducer';
import applications from 'src/components/Loan/Application/store/applications/reducer';
import assets from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Assets/store/reducer';
import otherAssets from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/OtherAssets/store/reducer';
import giftsGrants from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/GiftsGrants/store/reducer';
import liabilities from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Liabilities/store/reducer';
import otherLiabilities from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/OtherLiabilities/store/reducer';
import reoProperties from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Properties/store/reducer';
import alternateNames from 'src/components/Loan/Application/Sections/BorrowerInfo/Inputs/AlternateNames/store/reducer';
import otherIncomes from 'src/components/Loan/Application/Sections/IncomeInfo/Inputs/OtherIncomes/store/reducer';
import employments from 'src/components/Loan/Application/Sections/EmploymentInfo/Inputs/Employments/store/reducer';
import residences from 'src/components/Loan/Application/Sections/BorrowerInfo/Inputs/Residence/store/reducer';
import borrowerLoanApplications from 'src/components/Dashboard/store/reducer';
import dirtyApplicationForms from 'src/components/Loan/Application/store/reducers/dirty-forms';
import Axios from 'axios';
import env from 'src/env';
import { apiAxios } from 'src/util';

const isDev = process.env.NODE_ENV === 'development';
const selectedEnv = isDev
  ? env.getConfig(
      localStorage.getItem('crinch.development.environment') || 'test'
    )
  : env;

const defaultState = {
  working: [],
  error: '',
  warning: '',
  functionServer: selectedEnv.FUNCTION_URL,
  apiServer: selectedEnv.API_URL,
  clientID: selectedEnv.CLIENT_ID,
  activeEnvironment: selectedEnv.DEPLOY_ENV,
  loanLockedByOther: false,
  lastLoanGuid: null,
  appReady: false,
  dirtyForms: {},
};

Axios.defaults.baseURL = defaultState.functionServer;
apiAxios.defaults.baseURL = defaultState.apiServer;

const appState = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_working':
      if (data.action === 'start') {
        return {
          ...state,
          working: state.working.concat({
            uuid: data.uuid,
            name: data.name,
          }),
        };
      } else {
        return {
          ...state,
          working: state.working.filter((x) => x.uuid !== data.uuid),
        };
      }
    case 'set_error':
      if (state.error === data) return state;
      return {
        ...state,
        error: data,
      };
    case 'set_warning':
      if (state.warning === data) return state;
      return {
        ...state,
        warning: data,
      };
    case 'set_loan_locked':
      return {
        ...state,
        loanLockedByOther: data.isLockedByOther,
        lockId: data.id,
      };
    case 'set_last_loan_guid':
      return {
        ...state,
        lastLoanGuid: data,
      };
    case 'app_ready':
      return {
        ...state,
        appReady: true,
      };
    default:
      return state;
  }
};

export default () =>
  combineReducers({
    app: appState,
    loanOfficer,
    tasks,
    messages,
    borrowerLoanApplications,
    dirtyApplicationForms,
    loanNew,
    fields,
    applications,
    assets,
    otherAssets,
    giftsGrants,
    liabilities,
    otherLiabilities,
    alternateNames,
    otherIncomes,
    employments,
    reoProperties,
    residences,
  });
