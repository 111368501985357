import { workstate } from '.';
import { apiAxios, delay } from 'src/util';

export const deleteAttachmentAction = (taskId, attachmentId) => {
  return {
    type: 'delete_attachment',
    data: {
      taskId,
      attachmentId,
    },
  };
};

export const getCinchTasks = (loanGuid) => (dispatch) => {
  return workstate(dispatch, 'fetch-tasks', async () => {
    const res = await apiAxios.get(`loans/${loanGuid}/tasks`);
    if (res.data.some((item) => item.attachments.some((i) => i.size === 0))) {
      await delay(3000);
      await dispatch(getCinchTasks(loanGuid));
      return;
    }
    dispatch({
      type: 'set_tasks',
      data: res.data,
    });
  });
};

export const getTaskAttachmentDownloadUrls =
  (loanGuid, attachmentId) => (dispatch) => {
    return workstate(dispatch, 'view_attachment', async () => {
      try {
        const res = await apiAxios.get(
          `loans/${loanGuid}/tasks/attachment/${attachmentId}/urls`
        );
        return res.data;
      } catch (e) {
        return e;
      }
    });
  };

export const downloadTaskEFolderAttachment =
  (loanGuid, attachmentId, attachmentName) => async (dispatch) => {
    const urls = await dispatch(
      getTaskAttachmentDownloadUrls(loanGuid, attachmentId)
    );
    const originalUrls = urls?.originalUrls;
    if (originalUrls?.length) {
      const url = originalUrls[0];
      const res = await fetch(url);
      const buffer = await res.arrayBuffer();
      const file = new File([buffer], attachmentName, {
        type: res.headers.get('content-type'),
      });
      const objectUrl = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.download = attachmentName;
      a.href = objectUrl;
      a.click();
      a.remove();
      URL.revokeObjectURL(objectUrl);
    } else {
      throw new Error('Could not get a download url for this attachment.');
    }
  };

export const deleteTaskAttachment =
  (loanGuid, taskId, attachmentId) => (dispatch) => {
    return workstate(dispatch, 'delete-attachment', async () => {
      try {
        const res = await apiAxios.delete(
          `loans/${loanGuid}/tasks/${taskId}/files/${attachmentId}`
        );
        if (res.status === 200) {
          dispatch(deleteAttachmentAction(taskId, attachmentId));
        } else if (res.status === 204) {
          // server deletes the task, remove it
          dispatch({
            type: 'remove_task',
            data: {
              id: taskId,
            },
          });
        }
      } catch (e) {
        return e;
      }
    });
  };

export const sendCinchTaskActionResponse =
  (loanGuid, taskId, action, response) => (dispatch) => {
    return workstate(dispatch, 'update-task', async () => {
      const res = await apiAxios.post(
        `loans/${loanGuid}/tasks/${taskId}/actions`,
        {
          action,
          response,
        }
      );

      if (
        action === 'HomeOwnerInsuranceMortgagePrompt' &&
        response === true &&
        res.status === 204
      ) {
        // server deletes the task, remove it
        dispatch({
          type: 'remove_task',
          data: {
            id: taskId,
          },
        });
      } else {
        dispatch({
          type: 'update_task',
          data: res.data,
        });
      }
    });
  };
