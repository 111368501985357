import { useState, useEffect } from 'react';

export const useDebounce = (v, d) => {
  const [dvalue, setdvalue] = useState(v);
  useEffect(() => {
    const timer = setTimeout(() => {
      setdvalue(v);
    }, d);
    return () => clearTimeout(timer);
  }, [v, d]);

  return dvalue;
};
