import * as y from 'yup';
import { BorrowerOrCoBorrowerType } from 'src/types';

export const URLAAlternateNameContract = y.object({
  id: y.string().uuid(),
  firstName: y.string().required(),
  middleName: y.string().optional(),
  lastName: y.string().optional(),
  suffix: y.string().optional(),
  fullName: y.string().optional(),
});

export const StoreURLAAlternateNameContract = URLAAlternateNameContract.shape({
  owner: y
    .mixed<BorrowerOrCoBorrowerType>()
    .oneOf(Object.values(BorrowerOrCoBorrowerType)),
});

export interface URLAAlternateName
  extends y.Asserts<typeof URLAAlternateNameContract> {}
export interface StoreURLAAlternateName
  extends y.Asserts<typeof StoreURLAAlternateNameContract> {}

export enum URLAAlternateNameActionType {
  SET = 'set_alternate_names',
  ADD = 'add_alternate_name',
  DELETE = 'delete_alternate_name',
  UPDATE = 'update_alternate_name',
}

export const alternateNameNewValidationErrorMap = {
  _entityName: 'URLAAlternateName',
  borrower: {
    contact: {
      currentApplication: {
        borrower: {
          urlaAlternateNames: {
            '{0}': {
              firstName: 'Please enter your alternate first name',
            },
          },
        },
      },
    },
  },
};

export const coborrowerAlternateNameNewValidationErrorMap = {
  _entityName: 'URLAAlternateName',
  coborrower: {
    contact: {
      currentApplication: {
        coBorrower: {
          urlaAlternateNames: {
            '{0}': {
              firstName: "Please enter your coborrower's alternate first name",
            },
          },
        },
      },
    },
  },
};
