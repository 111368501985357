import * as y from 'yup';
import { BorrowerType } from 'src/types';

enum GsePropertyType {
  SingleFamily = 'SingleFamily',
  Condominium = 'Condominium',
  Townhouse = 'Townhouse',
  Cooperative = 'Cooperative',
  TwoToFourUnitProperty = 'TwoToFourUnitProperty',
  MultifamilyMoreThanFourUnits = 'MultifamilyMoreThanFourUnits',
  ManufacturedMobileHome = 'ManufacturedMobileHome',
  CommercialNonResidential = 'CommercialNonResidential',
  HomeAndBusinessCombined = 'HomeAndBusinessCombined',
  MixedUseResidential = 'MixedUseResidential',
  Farm = 'Farm',
  Land = 'Land',
}

enum PropertyUsageType {
  PrimaryResidence = 'PrimaryResidence',
  SecondHome = 'SecondHome',
  Investor = 'Investor',
  FHASecondaryResidence = 'FHASecondaryResidence',
}

enum UnitType {
  Apartment = 'Apartment',
  Basement = 'Basement',
  Building = 'Building',
  Condo = 'Condo',
  Department = 'Department',
  Floor = 'Floor',
  Front = 'Front',
  Hangar = 'Hangar',
  Key = 'Key',
  Lobby = 'Lobby',
  Lot = 'Lot',
  Lower = 'Lower',
  Office = 'Office',
  Penthouse = 'Penthouse',
  Pier = 'Pier',
  Rear = 'Rear',
  Room = 'Room',
  Side = 'Side',
  Space = 'Space',
  Stop = 'Stop',
  Suite = 'Suite',
  Trailer = 'Trailer',
  Unit = 'Unit',
  Upper = 'Upper',
}

enum FuturePropertyUsageType {
  PrimaryResidence = 'PrimaryResidence',
  SecondHome = 'SecondHome',
  Investment = 'Investment',
  Other = 'Other',
}

export const ReoPropertyContract = y
  .object({
    id: y.string().uuid(),
    // entity with title
    title: y.string().optional(),
    titlePhone: y.string().optional(),
    titleFax: y.string().optional(),
    // entity
    acquiredDate: y.string().optional(),
    city: y.string().required(),
    countryCode: y.string().optional(),
    dispositionStatusType: y.string().optional().when('propertyUsageType', {
      is: 'Investor',
      then: y.string().required(),
    }),
    gsePropertyType: y.mixed().oneOf(Object.values(GsePropertyType)),
    yearBuilt: y.string().optional(),
    liabilityDoesNotApply: y.boolean().optional(),
    includeInAusExport: y.boolean().optional(),
    lienInstallmentAmount: y.number().optional(),
    lienUpbAmount: y.number().optional(),
    maintenanceExpenseAmount: y.number(),
    marketValueAmount: y.number().positive(),
    noLinkToDocTrackIndicator: y.boolean().optional(),
    owner: y.mixed().oneOf(Object.values(BorrowerType)),
    participationPercentage: y.number().optional(),
    percentageOfRental: y.number().optional(),
    postalCode: y.string().required(),
    printAttachmentIndicator: y.boolean().optional(),
    printUserNameIndicator: y.boolean().optional(),
    propertyUsageType: y.mixed().oneOf(Object.values(PropertyUsageType)),
    purchasePrice: y.number().optional(),
    rentalIncomeGrossAmount: y
      .number()
      .optional()
      .when(
        ['propertyUsageType', 'dispositionStatusType'],
        // @ts-ignore
        (propertyUsageType, dispositionStatusType, schema) => {
          if (
            propertyUsageType === PropertyUsageType.Investor &&
            dispositionStatusType?.includes('Rental')
          ) {
            return schema.required();
          }
          return schema;
        },
      ),
    rentalIncomeNetAmount: y.number().optional(),
    reoComments: y
      .string()
      .required()
      .test(
        'reoCommentsTax',
        'Please enter the annual property tax amount',
        (str, ctx) => {
          return /Tax=[^;]+;/.test(str || '');
        },
      )
      .test(
        'reoCommentsIns',
        "Please enter the monthly homeowner's insurance amount",
        (str, ctx) => {
          return /Ins=[^;]+;/.test(str || '');
        },
      )
      .test(
        'reoCommentsHoa',
        'Please enter the monthly HOA dues',
        (str, ctx) => {
          return /HOA=[^;]+/.test(str || '');
        },
      ),
    requestDate: y.string().optional(),
    state: y.string().required(),
    streetAddress: y.string().optional(),
    subjectIndicator: y.boolean().optional(),
    unitNumber: y.string().optional(),
    unitType: y.mixed().oneOf(Object.values(UnitType)).optional(),
    urla2020StreetAddress: y.string().required(),
    numberOfUnits: y.number().optional(),
    futurePropertyUsageType: y
      .mixed()
      .oneOf(Object.values(FuturePropertyUsageType))
      .optional(),
    futureUnsageTypeOtherDescription: y.string().optional(),
    foreignAddressIndicator: y.boolean().optional(),
    country: y.string().optional(),
  })
  .strict();

export interface ReoProperty extends y.Asserts<typeof ReoPropertyContract> {}

export enum ReoPropertyActionType {
  SET = 'set_property',
  ADD = 'add_property',
  UPDATE = 'update_property',
  DELETE = 'delete_property',
}

export const reoPropertyNewValidationErrorMap = {
  _entityName: 'ReoProperty',
  'assets-liabilities': {
    properties: {
      currentApplication: {
        reoProperties: {
          '{0}': {
            reoCommentsTax: 'Please enter the annual property tax amount',
            reoCommentsIns:
              "Please enter the monthly homeowner's insurance amount",
            reoCommentsHoa: 'Please enter the monthly HOA dues',
            urla2020StreetAddress: 'Please enter the property street address',
            city: 'Please enter the property address city',
            state: 'Please enter the property address state',
            postalCode: 'Please enter the property address postal code',
            gsePropertyType: 'Please select the property type',
            propertyUsageType: "Please select the property's current use",
            dispositionStatusType: 'Please select property current status',
            rentalIncomeGrossAmount: 'Please enter monthly rental income',
            maintenanceExpenseAmount:
              'Please enter property maintenance expenses',
            marketValueAmount: 'Please enter estimated property market value',
            unitType: 'Please select the unit type',
            unitNumber: 'Please enter the unit number',
          },
        },
      },
    },
  },
};

export const coborrowerReoPropertyNewValidationErrorMap = {
  _entityName: 'ReoProperty',
  'assets-liabilities': {
    'coborrower-properties': {
      currentApplication: {
        reoProperties: {
          '{0}': {
            reoCommentsTax: 'Please enter the annual property tax amount',
            reoCommentsIns:
              "Please enter the monthly homeowner's insurance amount",
            reoCommentsHoa: 'Please enter the monthly HOA dues',
            urla2020StreetAddress: 'Please enter the property street address',
            city: 'Please enter the property address city',
            state: 'Please enter the property address state',
            postalCode: 'Please enter the property address postal code',
            gsePropertyType: 'Please select the property type',
            propertyUsageType: "Please select the property's current use",
            dispositionStatusType: 'Please select property current status',
            rentalIncomeGrossAmount: 'Please enter monthly rental income',
            maintenanceExpenseAmount:
              'Please enter property maintenance expenses',
            marketValueAmount: 'Please enter estimated property market value',
            unitType: 'Please select the unit type',
            unitNumber: 'Please enter the unit number',
          },
        },
      },
    },
  },
};
