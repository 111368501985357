import * as y from 'yup';
export type Guid = string & { isGuid: true };

export enum BorrowerOrCoBorrowerType {
  Borrower = 'Borrower',
  CoBorrower = 'CoBorrower',
}

export enum BorrowerType {
  Borrower = 'Borrower',
  CoBorrower = 'CoBorrower',
  Both = 'Both',
}

export enum ConsentType {
  CreditConsent = 'CreditConsent',
  EConsent = 'EConsent',
}

export enum ConsentAction {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export const EntityReferenceContract = y
  .object({
    entityId: y.string().uuid(),
    entityType: y.string().required(),
  })
  .strict();

export interface EntityReference
  extends y.Asserts<typeof EntityReferenceContract> {}

export interface EntityWithHolder {
  holderAddressCity?: string;
  holderAddressPostalCode?: string;
  holderAddressState?: string;
  holderAddressStreetLine1?: string;
  holderComments?: string;
  holderEmail?: string;
  holderFax?: string;
  holderName?: string;
  holderPhone?: string;
  attention?: string;
}

export interface EntityWithTitle {
  title?: string;
  titlePhone?: string;
  titleFax?: string;
}

export type IssuePathRecord = {
  [key: string]: IssuePathRecord | string;
};

export type ContractErrorMap = {
  _entityName: string;
  [key: string]: string | { [key: string]: IssuePathRecord };
};

export type SectionError = {
  _entityName: string;
  section: string;
  page: string;
  route: string;
  message: string;
  key: string;
  path?: string;
};
