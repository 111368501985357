import { compareDesc, parseISO } from 'date-fns';

const defaultState = [];

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_borrower_loan_applications':
      return data.sort((a, b) =>
        compareDesc(parseISO(a.createdAt), parseISO(b.createdAt)),
      );
    case 'add_borrower_loan_application': {
      return [...state, data].sort((a, b) =>
        compareDesc(parseISO(a.createdAt), parseISO(b.createdAt)),
      );
    }
    case 'update_borrower_loan_application': {
      const { loanGuid, ...keys } = data;
      const matchIndex = state.findIndex((x) => x.loanGuid === loanGuid);
      if (matchIndex === -1) return state;
      const items = [...state];
      const replacement = { ...items[matchIndex] };
      for (const key in keys) {
        if (keys[key] !== undefined) replacement[key] = keys[key];
      }

      items.splice(matchIndex, 1, replacement);
      return items;
    }
    default:
      return state;
  }
};

export default reducer;
