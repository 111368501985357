import { apiAxios } from 'src/util';
import {
  BorrowerOrCoBorrowerType,
  ConsentType,
  ConsentAction,
} from 'src/types';

export const getConsentStatus = async (
  loanGuid,
  applicationGuid,
  applicationType?: BorrowerOrCoBorrowerType,
  consentType?: ConsentType,
) => {
  const res = await apiAxios.get(
    `/consent/loans/${loanGuid}/applications/${applicationGuid}?applicantType=${
      applicationType ?? ''
    }&consentType=${consentType ?? ''}`,
  );
  return res;
};

export const requestConsent = async (
  loanGuid,
  applicationGuid,
  applicationType,
  consentType: ConsentType,
) => {
  const res = await apiAxios.post(
    `/consent/loans/${loanGuid}/applications/${applicationGuid}/${applicationType}/request?consentType=${consentType}`,
  );
  return res;
};

export const getConsentStatusFromHash = async (hash: string) => {
  return await apiAxios.get(`/consent/${hash}`);
};

export const updateConsent = async (hash: string, action: ConsentAction) => {
  await apiAxios.patch(`/consent/${hash}?action=${action}`);
};

export const updateBorrowerConsent = async (
  loanGuid,
  applicationGuid,
  consentType: ConsentType,
  action: ConsentAction,
) => {
  const res = await apiAxios.patch(
    `/consent/loans/${loanGuid}/applications/${applicationGuid}/borrower?consentType=${consentType}&action=${action}`,
  );
  return res;
};
