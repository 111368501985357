export const fieldIds = {
  loanPurpose: '19',
  hasCoborrower: 'CX.HASCOBORROWER',
  hasEmployment: 'CX.HASEMP',
  hasCoborrowerEmployment: 'CX.CHASEMP',
  hasOwnershipInterest: 'CX.CUROWNPROP',
  hasCoborrowerOwnershipInterest: 'CX.CCUROWNPROP',
  sameMailingAddressAsBorrower: 'CX.CMAILINGSAMEASB',
  sameAddressAsBorrower: 'CX.CADDRESSSAMEASB',
  borrowerMailingAddressSameAsPresent: '1819',
  priorPropertyTitleTypeBorrower: '1069',
  homeownerPastThreeYearsIndicatorBorrower: '403',
  homeownerPastThreeYearsIndicatorCoborrower: '1108',
  refinancingCurrentProperty: 'CX.REFICURPROP',
  isPreApproval: 'CX.PREAPPROVAL',
  hasRealtor: 'CX.HASREALTOR',
  needsRealtor: 'CX.NEEDSREALTOR',
  authorizationInsuranceQuote: 'CX.INSQUOTE',
  isAdjustableRateMortgage: '608',
};
