import * as y from 'yup';
import { BorrowerType } from 'src/types';
import { isEmpty } from 'src/util';

enum AssetType {
  LifeInsurance = 'LifeInsurance',
  RetirementFund = 'RetirementFund',
  NetWorthOfBusinessOwned = 'NetWorthOfBusinessOwned',
  Stock = 'Stock',
  Automobile = 'Automobile',
  CashOnHand = 'CashOnHand',
  OtherNonLiquidAssets = 'OtherNonLiquidAssets',
  // custom non-elliemae-enum strings cinch uses
  SavingsAccount = 'SavingsAccount',
  CheckingAccount = 'CheckingAccount',
  CashDepositOnSalesContract = 'CashDepositOnSalesContract',
  CertificateOfDepositTimeDeposit = 'CertificateOfDepositTimeDeposit',
  MoneyMarketFund = 'MoneyMarketFund',
  MutualFund = 'MutualFund',
  Bond = 'Bond',
  SecuredBorrowedFundsNotDeposited = 'SecuredBorrowedFundsNotDeposited',
  TrustAccount = 'TrustAccount',
  OtherLiquidAssets = 'OtherLiquidAssets',
}

const VodItemContract = y
  .object({
    itemNumber: y.number(),
    foreignAddressIndicator: y.boolean().optional(),
    type: y.mixed<AssetType>().oneOf(Object.values(AssetType)).optional(),
    accountIdentifier: y.string().optional(),
    cashOrMarketValueAmount: y.number().optional(),
    urla2020CashOrMarketValueAmount: y.number().optional(),
    depositoryAccountName: y.string().optional(),
    country: y.string().optional(),
  })
  .strict();

export const VodContract = y
  .object({
    id: y.string().required(),
    // entity with holder
    holderAddressCity: y.string().optional(),
    holderAddressPostalCode: y.string().optional(),
    holderAddressState: y.string().optional(),
    holderAddressStreetLine1: y.string().optional(),
    holderComments: y.string().optional(),
    holderEmail: y.string().optional(),
    holderFax: y.string().optional(),
    holderName: y.string().required(),
    holderPhone: y.string().optional(),
    attention: y.string().optional(),
    // entity with title
    title: y.string().optional(),
    titlePhone: y.string().optional(),
    titleFax: y.string().optional(),
    // vod-specific
    depositoryRequestDate: y.string().optional(),
    includeInAusReport: y.boolean().optional(),
    noLinkToDocTrackIndicator: y.boolean().optional(),
    owner: y.mixed().oneOf(Object.values(BorrowerType)),
    printAttachmentIndicator: y.boolean().optional(),
    printUserJobTitleIndicator: y.boolean().optional(),
    printUserNameIndicator: y.boolean().optional(),
    total: y.number().optional(),
    foreignAddressIndicator: y.boolean().optional(),
    country: y.string().optional(),
    items: y
      .array(VodItemContract)
      .test(
        'key:items.0.type',
        'Please select the asset type',
        (items, ctx) => {
          return items?.[0]?.type !== undefined;
        },
      )
      .test(
        'key:items.0.urla2020CashOrMarketValueAmount',
        'Please enter the market value amount',
        (items, ctx) => {
          return !isEmpty(items?.[0]?.urla2020CashOrMarketValueAmount);
        },
      ),
  })
  .strict();

/**
 * An Asset (Vod) entity describes a financial asset that the borrower or coborrower owns.
 */
export interface Asset extends y.Asserts<typeof VodContract> { }

/**
 * An AssetItem (VodItem) entity is a single account belonging to an Asset
 */
export interface AssetItem extends y.Asserts<typeof VodItemContract> { }

export enum AssetActionType {
  SET = 'set_assets',
  ADD = 'add_asset',
  UPDATE = 'update_asset',
  DELETE = 'delete_asset',
}

export const assetNewValidationErrorMap = {
  _entityName: 'Asset',
  'assets-liabilities': {
    assets: {
      currentApplication: {
        vods: {
          '{0}': {
            holderName: 'Please enter the bank/asset name',
            items: {
              0: {
                type: 'Please enter the bank/asset type',
                urla2020CashOrMarketValueAmount:
                  'Please enter the bank/asset amount',
              },
            },
          },
        },
      },
    },
  },
};

export const coborrowerNewAssetValidationErrorMap = {
  _entityName: 'Asset',
  'assets-liabilities': {
    'coborrower-assets': {
      currentApplication: {
        vods: {
          '{0}': {
            holderName: 'Please enter the bank/asset name',
            items: {
              0: {
                type: 'Please enter the bank/asset type',
                urla2020CashOrMarketValueAmount:
                  'Please enter the bank/asset amount',
              },
            },
          },
        },
      },
    },
  },
};
