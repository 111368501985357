import React, { createContext, useState, useRef, useContext } from 'react';
import ConfirmationDialog from 'src/components/Dialog/ConfirmDialog';
const ConfirmationContext = createContext(Promise.reject);

export const ConfirmationProvider = ({ children }) => {
  const [state, setState] = useState(null);
  const awaitingRef = useRef();

  const open = (options) => {
    setState(options);
    return new Promise((resolve, reject) => {
      awaitingRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (state.catchOnCancel && awaitingRef.current) {
      awaitingRef.current.resolve(false);
    }
    setState(null);
  };

  const handleSubmit = () => {
    if (awaitingRef.current) awaitingRef.current.resolve(true);
    setState(null);
  };

  return (
    <>
      <ConfirmationContext.Provider value={open}>
        {children}
      </ConfirmationContext.Provider>
      <ConfirmationDialog
        open={Boolean(state)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...state}
      />
    </>
  );
};

/**
 * @returns {(options: {
 *   variant: 'confirm' | 'alert',
 *   title?: string,
 *   description?: string,
 *   buttons?: {
 *     submit?: string,
 *     cancel?: string
 *   }
 * }) => Promise<boolean>}
 */
export const useConfirmation = () => useContext(ConfirmationContext);
