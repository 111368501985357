export default {
  name: 'production',
  hostnames: ['cinchborrowerprod.azurefd.net', 'apply.fmm.com', 'mr.fmm.com'],
  values: {
    FUNCTION_URL: 'https://func-cinch-serverside.azurewebsites.net/api/',
    API_URL: 'https://app-cinch-borrower.azurewebsites.net/api/',
    AUTH_DOMAIN: 'login.fmm.com',
    CLIENT_ID: '5ur8d5TUrP8RPG9ivEipe9GEJ0fS3I4x',
    AI_INSTRUMENTATION_KEY: 'b32bdee8-9298-4de6-b5e3-093eedb5d028',
    DEPLOY_ENV: 'production',
    LO_URL: 'https://cinch.fmm.com',
    LOGROCKET_APPID: 'fmm/cinch-borrower',
  },
};
