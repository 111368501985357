import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const texts = {
  confirm: {
    cancel: 'Cancel',
    submit: 'Continue',
  },
  alert: {
    submit: 'OK',
  },
  info: {
    submit: 'Close',
  },
};

const ConfirmationDialog = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose,
  reverse,
  text: _text,
}) => {
  const text = _text || texts[variant];
  const submitProps =
    text.cancel && !reverse
      ? {
        color: 'primary',
        variant: 'contained',
      }
      : null;
  const cancelProps = reverse
    ? {
      color: 'primary',
      variant: 'contained',
    }
    : null;

  return (
    <Dialog open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {text.cancel && (
          <Button {...cancelProps} onClick={onClose} autoFocus>
            {text.cancel}
          </Button>
        )}
        {text.submit && (
          <Button {...submitProps} onClick={onSubmit}>
            {text.submit}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = {
  open: false,
  onSubmit: () => null,
  onClose: () => null,
  variant: 'confirm',
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['confirm', 'alert', 'info']),
  reverse: PropTypes.bool,
  text: PropTypes.shape({
    submit: PropTypes.string.isRequired,
    cancel: PropTypes.string,
  }),
};

export default ConfirmationDialog;
