import { fieldIds } from 'src/util';
import * as y from 'yup';

export enum UnitType {
  Apartment = 'Apartment',
  Basement = 'Basement',
  Building = 'Building',
  Condo = 'Condo',
  Department = 'Department',
  Floor = 'Floor',
  Front = 'Front',
  Hangar = 'Hangar',
  Key = 'Key',
  Lobby = 'Lobby',
  Lot = 'Lot',
  Lower = 'Lower',
  Office = 'Office',
  Penthouse = 'Penthouse',
  Pier = 'Pier',
  Rear = 'Rear',
  Room = 'Room',
  Side = 'Side',
  Space = 'Space',
  Stop = 'Stop',
  Suite = 'Suite',
  Trailer = 'Trailer',
  Unit = 'Unit',
  Upper = 'Upper',
}

export const PropertyContract = y.object({
  loanPurposeType: y.string().required(),
  loanPurposeTypeOtherDescription: y.string().optional(),
  loanPurposeTypeUrla: y.string().optional(),
  addressLineText: y.string().when(
    ['loanPurposeType', '$loan'],
    // @ts-ignore
    (loanPurposeType, loan, schema) => {
      if (isPreApproval(loanPurposeType, loan)) return schema.optional();
      return schema.required();
    },
  ),
  city: y.string().when(
    ['loanPurposeType', '$loan'],
    // @ts-ignore
    (loanPurposeType, loan, schema) => {
      if (isPreApproval(loanPurposeType, loan)) return schema.optional();
      return schema.required();
    },
  ),
  state: y.string().when(
    ['loanPurposeType', '$loan'],
    // @ts-ignore
    (loanPurposeType, loan, schema) => {
      if (isPreApproval(loanPurposeType, loan)) return schema.optional();
      return schema.required();
    },
  ),
  postalCode: y.string().when(
    ['loanPurposeType', '$loan'],
    // @ts-ignore
    (loanPurposeType, loan, schema) => {
      if (isPreApproval(loanPurposeType, loan)) return schema.optional();
      return schema.required();
    },
  ),
  county: y.string().when(
    ['loanPurposeType', '$loan'],
    // @ts-ignore
    (loanPurposeType, loan, schema) => {
      if (isPreApproval(loanPurposeType, loan)) return schema.optional();
      return schema.required();
    },
  ),
  refinancePropertyAcquiredYear: y.string().optional(),
  financedNumberOfUnits: y.number(),
  refinancePropertyOriginalCostAmount: y
    .number()
    .optional()
    .when('loanPurposeType', {
      is: (val?: string) => val?.includes('Refinance'),
      then: y.number().required(),
    }),
  refinancePropertyExistingLienAmount: y
    .number()
    .optional()
    .when('loanPurposeType', {
      is: (val?: string) => val?.includes('Refinance'),
      then: y.number().required(),
    }),
  addressUnitDesignatorType: y
    .mixed<UnitType>()
    .oneOf(Object.values(UnitType))
    .optional(),
  addressUnitIdentifier: y.string().optional(),
  propertyMixedUsageIndicator: y.boolean(),
});

const isPreApproval = (loanPurposeType, loan) => {
  return (
    loanPurposeType === 'Purchase' &&
    loan?.customFields[fieldIds.isPreApproval] === 'true'
  );
};

export interface Property extends y.Asserts<typeof PropertyContract> {}

export const propertyNewValidationErrorMap = {
  _entityName: 'Property',
  loan: {
    'about-property': {
      property: {
        addressUnitDesignatorType: 'Please select the property unit type',
        addressUnitIdentifier: 'Please enter the unit number',
        addressLineText: 'Please enter the property street address',
        city: 'Please enter the property city',
        state: 'Please enter the property state',
        postalCode: 'Please enter the property postalCode',
        county: 'Please enter the property county',
        financedNumberOfUnits: 'Please enter the number of units',
        refinancePropertyAcquiredYear:
          'Please enter the year the property was acquired',
      },
    },
    purpose: {
      property: {
        loanPurposeType: 'Please select the purpose of this loan',
      },
    },
    'loan-amount': {
      property: {
        refinancePropertyExistingLienAmount:
          'Please enter your existing mortgage balance',
      },
    },
    business: {
      property: {
        propertyMixedUsageIndicator:
          'Please select if you will be setting aside space to operate your own business',
      },
    },
  },
};
