import {
  FieldActionType,
  FieldDictionary,
} from 'src/components/Loan/Application/store/fields/actions';
import { Field } from 'src/types/field';

const defaultState: FieldDictionary = {};

const reducer = (
  state: FieldDictionary = defaultState,
  { type, data }: { type: FieldActionType; data: any },
): FieldDictionary => {
  switch (type) {
    case FieldActionType.SET:
      return data.reduce((a: FieldDictionary, item: Field) => {
        return {
          ...a,
          [item.fieldName]: item.value,
        };
      }, {});
    case FieldActionType.UPDATE:
      return {
        ...state,
        ...data,
      };
    // @ts-ignore
    case 'clear_loan_data': {
      return defaultState;
    }
    default:
      return state;
  }
};

export default reducer;
