import { anyAxios, apiAxios } from 'src/util';
import { IMAGETYPE_JPEG } from 'src/util/constant';

const handleFileUpload = async (file, uploadUrlData) => {
  // convert specific file types
  file = await convertFileTypeByExtension(file);

  const headers = {
    'Content-Type': file.type,
  };
  if (uploadUrlData.authorizationHeader) {
    headers.Authorization = uploadUrlData.authorizationHeader;
  }

  if (uploadUrlData.multiChunkRequired) {
    const { chunkList, commitUrl } = uploadUrlData.multiChunk;
    let offset = 0;
    for (const { size, uploadUrl } of chunkList) {
      const chunk = file.slice(offset, size);
      await anyAxios.put(uploadUrl, chunk, {
        headers,
      });
      offset += size;
    }

    await anyAxios.post(commitUrl, null, {
      headers: {
        Authorization: uploadUrlData.authorizationHeader,
      },
    });
  } else {
    await anyAxios.put(uploadUrlData.uploadUrl, file, {
      headers,
    });
  }
};

export const uploadTaskFileToEFolder =
  ({ loanGuid, taskId, title = '', file, category }) =>
  async () => {
    if (file.size === 0) throw new Error('File is empty');
    if (!title.includes('.')) throw new Error('Missing file extension');

    const uploadUrlRes = await apiAxios.post(
      `loans/${loanGuid}/tasks/${taskId}/files`,
      {
        title,
        category,
        size: file.size,
      }
    );
    const uploadUrlData = uploadUrlRes.data;

    await handleFileUpload(file, uploadUrlData);
    const attachmentId = uploadUrlData.attachmentId;
    const confirmedRes = await apiAxios.post(
      `loans/${loanGuid}/tasks/${uploadUrlData.taskId}/files/${attachmentId}/complete`
    );

    return confirmedRes.data;
  };

export const triggerEFolderAttachmentNotification = (loanGuid) => async () => {
  try {
    const res = await apiAxios.post(
      `loans/${loanGuid}/tasks/attachments/notify`
    );
    return res.data;
  } catch (e) {
    return e;
  }
};

/**
 * Convert file to specific file type based on file extension
 * @param {File} file
 * @returns {File}
 */
export const convertFileTypeByExtension = async (file) => {
  const fileExt = getFileExtension(file.name);
  // convert png files to jpg since not all providers handle png uploads
  if (fileExt === 'png') {
    file = await convertPNGToJPG(file);
  }
  return file;
};

const getFileExtension = (fileName) => {
  const regex = /(?:\.([^.]+))?$/;
  const extension = regex.exec(fileName)[1];
  return extension;
};

const convertPNGToJPG = async (file) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const img = new Image();
  img.src = URL.createObjectURL(file);
  await img.decode();

  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0);

  const blob = await getBlob(canvas, IMAGETYPE_JPEG);

  img.remove();
  canvas.remove();

  return blob;
};

const getBlob = (a, fileType) => {
  return new Promise((resolve) => {
    return a.toBlob(resolve, fileType);
  });
};
