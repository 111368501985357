import { useSelector } from 'react-redux';
import { StoreURLAAlternateName } from 'src/components/Loan/Application/Sections/BorrowerInfo/Inputs/AlternateNames/store/types';
import { BorrowerOrCoBorrowerType, BorrowerType, Guid } from 'src/types';
import { Application, StoreApplication } from 'src/types/application';
import { StoreBorrower } from 'src/types/borrower';
import { StoreLoan } from 'src/types/loan';
import { Asset } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Assets/store/types';
import { OtherAsset } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/OtherAssets/store/types';
import { GiftGrant } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/GiftsGrants/store/types';
import { Liability } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Liabilities/store/types';
import { OtherLiability } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/OtherLiabilities/store/types';
import { OtherIncome } from 'src/components/Loan/Application/Sections/IncomeInfo/Inputs/OtherIncomes/store/types';
import { StoreEmployment } from 'src/components/Loan/Application/Sections/EmploymentInfo/Inputs/Employments/store/types';
import { ReoProperty } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Properties/store/types';
import {
  ResidencyType,
  StoreResidence,
} from 'src/components/Loan/Application/Sections/BorrowerInfo/Inputs/Residence/store/types';
import { BorrowerLoanApplication } from 'src/types/BorrowerLoanApplication';
import statesOptions from 'src/util/states.json';

/**
 * Get the loan officer
 */
export const useLoanOfficer = () => {
  // @ts-ignore
  return useSelector((store) => store.loanOfficer);
};

export const useLoanOfficerLicenses = () => {
  const licenses = useSelector(
    (store) =>
      // @ts-ignore
      store.loanOfficer?.licenses,
  );
  // filter inside useSelector causes rerenders
  return licenses?.filter((license) => license.enabled === true);
};

export const useLoanOfficerLicensedStates = () => {
  const licenses = useLoanOfficerLicenses();
  const allowedStates = licenses?.map((license) => license.state);
  const filteredStatesOptions = statesOptions?.filter((state) => {
    if (allowedStates?.includes(state.value)) {
      return state;
    }
    return null;
  });
  return filteredStatesOptions;
};

export const useLoanApplications = (): BorrowerLoanApplication[] => {
  // @ts-ignore
  return useSelector((store) => store.borrowerLoanApplications);
};

export const useLoanApplication = (
  loanGuid: string,
): BorrowerLoanApplication | undefined => {
  const apps = useLoanApplications();
  return apps.find((x) => x.loanGuid === loanGuid);
};

/**
 * Get the loan
 */
export const useLoan = (): StoreLoan => {
  // @ts-ignore
  return useSelector((store) => store.loanNew);
};

/**
 * Get the loan fields
 */
export const useLoanFields = (): {
  [key: string]: string | number | boolean | null;
} => {
  // @ts-ignore
  return useSelector((store) => store.fields);
};

/**
 * Get all applications
 */
export const useApplications = (): StoreApplication[] => {
  // @ts-ignore
  return useSelector((store) => store.applications);
};

/**
 * Get the application matching the guid provided
 */
export const useApplication = (applicationId: Guid): StoreApplication => {
  if (!applicationId) {
    throw new Error('useApplication missing parameter applicationId');
  }
  return useSelector((store) =>
    // @ts-ignore
    store.applications.find((x: Application) => x.id === applicationId),
  );
};

/**
 * Get the current application
 */
export const useCurrentApplication = (): StoreApplication => {
  // @ts-ignore
  return useSelector(
    // @ts-ignore
    (store) => store.applications[0],
  );
};

/**
 * Get the Borrower or CoBorrower for the current application
 */
export const useBorrower = (BorC: BorrowerOrCoBorrowerType): StoreBorrower => {
  if (!BorC) throw new Error('useBorrower missing parameter BorC');
  const applicationOwnerKey =
    BorC === BorrowerOrCoBorrowerType.Borrower ? 'borrower' : 'coBorrower';
  const application = useCurrentApplication();
  return application?.[applicationOwnerKey];
};

/**
 * Get the Borrower or CoBorrower URLAAlternateNames
 */
export const useAlternateNames = (
  BorC: BorrowerOrCoBorrowerType,
): StoreURLAAlternateName[] => {
  if (!BorC) throw new Error('useAlternateNames missing BorC');
  const names: StoreURLAAlternateName[] = useSelector(
    // @ts-ignore
    (store) => store.alternateNames,
  );
  return names.filter((x) => x.owner === BorC);
};

/**
 * Get the Borrower or CoBorrower Assets
 */
export const useAssets = (BorC?: BorrowerType): Asset[] => {
  // @ts-ignore
  const assets: Asset[] = useSelector((store) => store.assets);
  if (!BorC) return assets;
  return assets.filter((x) => x.owner === BorC);
};

/**
 * Get the Borrower or CoBorrower OtherAssets
 */
export const useOtherAssets = (BorC?: BorrowerType): OtherAsset[] => {
  // @ts-ignore
  const assets: OtherAsset[] = useSelector((store) => store.otherAssets);
  if (!BorC) return assets;
  return assets.filter((x) => x.borrowerType === BorC);
};

/**
 * Get the Gifts and Grants
 */
export const useGiftsGrants = (BorC?: BorrowerType): GiftGrant[] => {
  // @ts-ignore
  const giftsGrants: GiftGrant[] = useSelector((store) => store.giftsGrants);
  if (!BorC) return giftsGrants;
  return giftsGrants.filter((x) => x.owner === BorC);
};

/**
 * Get the Borrower or CoBorrower Liabilities
 */
export const useLiabilities = (
  BorC?: BorrowerType,
  additionalFilter?: (
    liability: Liability,
    index: number,
    array: Liability[]
  ) => boolean,
): Liability[] => {
  // @ts-ignore
  let liabilities: Liability[] = useSelector((store) => store.liabilities);
  if (additionalFilter) {
    liabilities = liabilities.filter(additionalFilter);
  }
  if (!BorC) return liabilities;
  return liabilities.filter((x) => x.owner === BorC);
};

/**
 * Get the Borrower or CoBorrower OtherLiabilities
 */
export const useOtherLiabilities = (BorC?: BorrowerType): OtherLiability[] => {
  const otherLiabilities: OtherLiability[] = useSelector(
    // @ts-ignore
    (store) => store.otherLiabilities,
  );
  if (!BorC) return otherLiabilities;
  return otherLiabilities.filter((x) => x.borrowerType === BorC);
};

/**
 * Get the Borrower or CoBorrower Employments
 */
export const useEmployments = (
  BorC: BorrowerOrCoBorrowerType,
): StoreEmployment[] => {
  if (!BorC) throw new Error('useEmployments missing BorC');
  const employments: StoreEmployment[] = useSelector(
    // @ts-ignore
    (store) => store.employments,
  );
  return employments.filter((x) => x.owner === BorC);
};

/**
 * Get the Borrower or CoBorrower OtherIncomes
 */
export const useOtherIncomes = (BorC?: BorrowerType): OtherIncome[] => {
  const otherIncomes: OtherIncome[] = useSelector(
    // @ts-ignore
    (store) => store.otherIncomes,
  );
  if (!BorC) return otherIncomes;
  return otherIncomes.filter((x) => x.owner === BorC);
};

/**
 * Get the Borrower or CoBorrower ReoProperties
 */
export const useReoProperties = (BorC?: BorrowerType): ReoProperty[] => {
  const properties: ReoProperty[] = useSelector(
    // @ts-ignore
    (store) => store.reoProperties,
  );
  if (!BorC) return properties;
  return properties.filter((x) => x.owner === BorC);
};

/**
 * Get the Borrower or CoBorrower residences (FR fields)
 */
export const useResidences = (
  BorC: BorrowerOrCoBorrowerType,
): StoreResidence[] => {
  if (!BorC) throw new Error('useResidences missing BorC');
  const residences: StoreResidence[] = useSelector(
    // @ts-ignore
    (store) => store.residences,
  );
  return residences.filter((x) => x.owner === BorC);
};

/**
 * Get the Borrower or CoBorrower Current residence (FR fields)
 */
export const useCurrentResidence = (
  BorC: BorrowerOrCoBorrowerType,
): StoreResidence | undefined => {
  if (!BorC) throw new Error('useCurrentResidence missing BorC');
  const residences: StoreResidence[] = useSelector(
    // @ts-ignore
    (store) => store.residences,
  );
  return residences
    .filter((x) => x.owner === BorC)
    .find((x) => x.residencyType === ResidencyType.Current);
};
