import { fieldIds } from 'src/util';
import * as y from 'yup';

export const FieldContract = y
  .object({
    fieldName: y.string().required(),
    value: y.mixed(),
    format: y.string(),
  })
  .strict();

export interface Field extends y.Asserts<typeof FieldContract> {}

// @ts-ignore
export const ValidationFieldContract = y.lazy(() =>
  y.object({
    [fieldIds.isPreApproval]: y.mixed().oneOf(['true', 'false']),
    [fieldIds.refinancingCurrentProperty]: y
      .mixed()
      .oneOf(['true', 'false'])
      .when('$loan', (loan, schema) => {
        if (loan?.property?.loanPurposeType?.includes('Refinance')) {
          return schema.required();
        }
        return schema.optional();
      }),
    [fieldIds.hasRealtor]: y.string().when(fieldIds.isPreApproval, {
      is: 'true',
      then: y.string().required(),
      otherwise: y.string().optional(),
    }),
    [fieldIds.needsRealtor]: y
      .string()
      .when(fieldIds.isPreApproval, {
        is: 'true',
        then: y.string().required(),
        otherwise: y.string().optional(),
      })
      .when(fieldIds.hasRealtor, {
        is: 'true',
        then: y.string().optional(),
      }),
    [fieldIds.hasEmployment]: y.mixed().oneOf(['true', 'false']),
    [fieldIds.hasOwnershipInterest]: y.mixed().oneOf(['true', 'false']),
    // coborrower
    [fieldIds.sameAddressAsBorrower]: y.mixed().oneOf(['Y', 'N']),
    [fieldIds.sameMailingAddressAsBorrower]: y.mixed().oneOf(['Y', 'N']),
    [fieldIds.hasCoborrowerEmployment]: y.mixed().oneOf(['true', 'false']),
    [fieldIds.hasCoborrowerOwnershipInterest]: y
      .mixed()
      .oneOf(['true', 'false']),
  }),
);

export const fieldValidationErrorMap = {
  _entityName: 'Field',
  loan: {
    refinancing: {
      [fieldIds.refinancingCurrentProperty]:
        'Please select if your are refinancing your current property',
    },
    'found-agent': {
      [fieldIds.hasRealtor]: 'Please select if youve found a real estate agent',
    },
    'needs-agent': {
      [fieldIds.needsRealtor]:
        'Please select if you would like FM Home Loans to refer you to one of their trusted real estate agents',
    },
  },
  employment: {
    status: {
      [fieldIds.hasEmployment]:
        'Please select if you have been employed in the past two years',
    },
  },
  'assets-liabilities': {
    information: {
      [fieldIds.hasOwnershipInterest]:
        'Please select whether you have an ownership stake in any property',
    },
  },
};

export const coborrowerFieldValidationErrorMap = {
  _entityName: 'Field',
  coborrower: {
    'shares-address-with-borrower': {
      [fieldIds.sameAddressAsBorrower]:
        'Please select whether your coborrower shared the same address as you',
    },
    'shares-mailing-address': {
      [fieldIds.sameMailingAddressAsBorrower]:
        'Please select whether your coborrower shares the same mailing address as you',
    },
  },
  employment: {
    'coborrower-status': {
      [fieldIds.hasCoborrowerEmployment]:
        'Please select whether your coborrower is currently employed or has been employed in the last two years',
    },
  },
  'assets-liabilities': {
    'coborrower-information': {
      [fieldIds.hasCoborrowerOwnershipInterest]:
        'Please select whether your coborrower has an ownership stake in any property',
    },
  },
};
