import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import styles from './index.module.scss';

export default function Loading ({
  fontSize,
  label = 'LOADING',
  dots,
  hideCircle,
  horizontal,
  ...props
}) {
  return (
    <div
      className={clsx(
        styles.loading,
        'flex flex-container flex-center',
        horizontal && styles.horizontal,
      )}
    >
      {!hideCircle && <CircularProgress className={styles.circle} {...props} />}

      <Typography
        className={clsx(dots ? styles.dots : '', styles.label)}
        style={{ fontSize: fontSize + 'px' }}
      >
        {label}
      </Typography>
    </div>
  );
}
