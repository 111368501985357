import { workstate } from 'src/actions';
import { apiAxios } from 'src/util';

export const getInvitationData = async (hash) => {
  const res = await apiAxios.get(`invitations/${hash}`);
  return res.data;
};

export const getInvitationLoanSummary = async (hash) => {
  const res = await apiAxios.get(`invitations/${hash}/summary`);
  return res.data;
};

export const acceptInvitation = (hash) => (dispatch) => {
  return workstate(dispatch, async () => {
    const res = await apiAxios.post(`/invitations/${hash}/accept`);
    dispatch({
      type: 'add_borrower_loan_application',
      data: res.data,
    });
    return res.data;
  });
};

export const resendCoborrowerInvitation = async (data) => {
  const res = await apiAxios.post('invitations/resend-invitation', data);
  return res.data;
};

export const getAcceptedInvitationGuids = async (loanGuid) => {
  const res = await apiAxios.get(`invitations/${loanGuid}/accepted`);
  return res.data;
};
