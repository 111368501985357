export enum LoanActionType {
  UPDATE = 'update_loan_new',
}

export enum LoanDirtyStateActionType {
  UPDATE = 'update_loan_dirty_state',
}

export enum ApplicationActionType {
  SET = 'set_applications',
  UPDATE = 'update_application',
  ADD = 'add_application',
}
