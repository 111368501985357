/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from 'react';
import authconnect, {
  useAuth,
  useAuthSession,
  useIsAuthenticated,
  useIsLoanOfficer,
} from './authconnect';
import { createStore as createAuthStore } from './Auth';

const AuthContext = React.createContext({});
const defaultState = {
  user: null,
  session: null,
  error: null,
  working: false,
  logout: () => null,
};
const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_working':
      return {
        ...state,
        working: data,
      };
    case 'set_data':
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};

const AuthProvider = ({ store, children }) => {
  const [state, dispatch] = useReducer(reducer, store);
  useEffect(() => {
    // weird hack to update the state the first time
    state.dispatch = dispatch;
  }, []);

  const getState = () => state;

  return (
    <AuthContext.Provider value={{ store: { dispatch, getState } }}>
      {children}
    </AuthContext.Provider>
  );
};

export {
  authconnect,
  AuthContext,
  AuthProvider,
  createAuthStore,
  useAuth,
  useAuthSession,
  useIsLoanOfficer,
  useIsAuthenticated,
};
