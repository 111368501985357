import DateFnsUtils from '@date-io/date-fns';
import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GoogleMapsProvider } from 'src/components/GoogleMapsProvider';
import env from 'src/env';
import { apiAxios } from 'src/util';
import { sanitizeRequest, sanitizeResponse } from 'src/util/networksanitizer';
import App from './App';
import { AuthProvider } from './Auth';
import { BugProvider, ConfirmationProvider } from './hooks';
import { authStore, store } from './store';

console.log(
  `Build #: %c${process.env.REACT_APP_BUILD_NUMBER ?? 'LOCAL'}`,
  'color: #ffffff; font-weight: bold; background: #00acc4; border-radius: 13px; padding: 2px 10px;'
);

if (env.LOGROCKET_APPID) {
  LogRocket.init(env.LOGROCKET_APPID, {
    network: {
      requestSanitizer: (request) => {
        request = sanitizeRequest(request);
        if (request?.url?.toLowerCase()?.indexOf('visualstudio') !== -1) {
          return null;
        }
        return request;
      },
      responseSanitizer: sanitizeResponse,
    },
  });
  setupLogRocketReact(LogRocket);
  LogRocket.getSessionURL((sessionURL) => {
    apiAxios.defaults.headers.common['X-LogRocket-URL'] = sessionURL;
  });
}

const theme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: '#0164a4',
      dark: '#00416b',
      light: '#007ccc',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#0da8d2',
      dark: '#0d87a9',
      light: '#10bfef',
      contrastText: '#000000',
    },
    success: {
      main: '#79c406',
      dark: '#5f9a04',
      light: '#8be008',
      contrastText: '#000000',
    },
    background: {
      default: '#f2f2f2',
      paper: '#ffffff',
    },
  },
  typography: {
    h1: {
      fontSize: 40,
    },
    h2: {
      fontSize: 34,
    },
    h3: {
      fontSize: 28,
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        margin: '0',
      },
      maxWidthLg: {
        maxWidth: '1400px !important',
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiLink: {
      root: {
        color: '#0da8d2',
      },
    },
  },
  mixins: {
    ellip: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  transitions: {
    transition: 'cubic-bezier(0.23, 0.54, 0.19, 0.99)',
  },
  shadowsLight: [
    '0 1.5px 4px rgba(0, 0, 0, 0.12), 0 1.5px 6px rgba(0, 0, 0, 0.06)',
    '0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12)',
    '0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28)',
    '0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  ],
});

const mapsApiKey = 'AIzaSyBfm1-zWTPuNPLYWXWbRQPgs-tyguqkEiE';
const mapsLibraries = ['places'];

const elem = document.getElementById('root');
const render = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <BrowserRouter>
            <HelmetProvider>
              <AuthProvider store={authStore}>
                <ConfirmationProvider>
                  <BugProvider>
                    <GoogleMapsProvider
                      googleMapsApiKey={mapsApiKey}
                      language='en'
                      libraries={mapsLibraries}
                    >
                      <CssBaseline />
                      <Component />
                    </GoogleMapsProvider>
                  </BugProvider>
                </ConfirmationProvider>
              </AuthProvider>
            </HelmetProvider>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>,
    elem
  );
};

if (elem) render(App);

if (process.env.NODE_ENV === 'development' && module.hot && elem) {
  module.hot.accept('src/App', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const nextApp = require('src/App').default;
    console.clear();
    render(nextApp);
  });
  module.hot.accept('src/reducers', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const reducers = require('src/reducers').default;
    store.replaceReducer(reducers());
  });
}
