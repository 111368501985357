import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

/**
 * @param {string?} expectedSection
 * @param {string?} expectedPage
 * @returns {{ loanGuid?: string, section?: string, page?: string, valid: boolean }}
 */
export const useApplicationPage = (
  expectedSection = null,
  expectedPage = null,
) => {
  const routeMatch = useRouteMatch('/apply/:loanGuid/:section/:page?');
  let result = {
    loanGuid: null,
    section: null,
    page: null,
  };
  if (routeMatch) {
    result = {
      ...result,
      ...routeMatch.params,
    };
  }
  const section = result.section;
  const page = result.page;
  const loanGuid = result.loanGuid;
  return useMemo(() => {
    const expectedMatches =
      (expectedSection ? expectedSection === section : true) &&
      (expectedPage ? expectedPage === page : true);
    return {
      loanGuid,
      section,
      page,
      valid: loanGuid && section && page && expectedMatches,
    };
  }, [loanGuid, section, page, expectedSection, expectedPage]);
};
