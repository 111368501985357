/* eslint-disable space-before-function-paren */
import { Button, Container, Link } from '@material-ui/core';
import { mdiBug } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Route } from 'react-router-dom';
import bbbImg from 'src/assets/img/Footer/bbb.png';
import equalHousingImg from 'src/assets/img/Footer/equalhousing.png';
import facebookImage from 'src/assets/img/Footer/social-fb.svg';
import instagramImage from 'src/assets/img/Footer/social-ig.svg';
import linkedinImage from 'src/assets/img/Footer/social-linkedin.svg';
import twitterImage from 'src/assets/img/Footer/social-twitter.svg';
import yelpImage from 'src/assets/img/Footer/social-yelp.svg';
import { LoanOfficerCard } from 'src/components/Header/LoanOfficerCard';
import { useBugReport } from 'src/hooks';
import './index.scss';

const guidMatchStr =
  '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

const Footer = () => {
  const bugReport = useBugReport();

  return (
    <footer className='app-footer'>
      <Container>
        <BrowserView viewClassName='browser'>
          <hr />
          <div className='footer-content flex-container'>
            {renderAddress()}
            <div className='flex bugReport'>
              <Button
                onClick={async () => await bugReport()}
                startIcon={<Icon path={mdiBug} />}
                variant='contained'
                color='primary'
                size='small'
              >
                Contact Support
              </Button>
            </div>
            <div className='links flex-container'>
              {renderSocialMedia()}
              {renderEqualBBB()}
            </div>
          </div>
          {renderContactInfo()}
        </BrowserView>
        <MobileView viewClassName='mobile'>
          <hr />
          <Route
            path={`/:section(apply|postapplication)/:guid(${guidMatchStr})`}
          >
            {({ match }) => {
              if (!match) return null;
              return <LoanOfficerCard loanGuid={match.params.guid} />;
            }}
          </Route>
          <div className='footer-content flex-container'>
            {renderAddress()}
            {renderContactInfo()}
            <div className='flex bugReport'>
              <Button
                onClick={async () => await bugReport()}
                startIcon={<Icon path={mdiBug} />}
                variant='contained'
                color='primary'
                size='small'
              >
                Contact Support
              </Button>
            </div>
            {renderSocialMedia()}
            <div className='links flex-container'>{renderEqualBBB()}</div>
          </div>
        </MobileView>
      </Container>
    </footer>
  );
};
const socialMediaList = [
  {
    filename: yelpImage,
    alt: 'yelp',
    link: 'https://www.yelp.com/biz/fm-home-loans-midwood-brooklyn',
  },
  {
    filename: twitterImage,
    alt: 'twitter',
    link: 'https://twitter.com/FMHomeLoans',
  },
  {
    filename: facebookImage,
    alt: 'facebook',
    link: 'https://www.facebook.com/fmhomeloans',
  },
  {
    filename: linkedinImage,
    alt: 'linkedin',
    link: 'https://www.linkedin.com/company/fmhomeloansllc/',
  },
  {
    filename: instagramImage,
    alt: 'instagram',
    link: 'https://www.instagram.com/fmhomeloans/',
  },
];
function renderSocialMedia() {
  const socialMediaEls = socialMediaList.map((image, i) => (
    <a href={image.link} target='_blank' rel='noopener noreferrer' key={i}>
      <img className='sm-item' src={image.filename} alt={image.alt} />
    </a>
  ));

  return <div className='social-media'>{socialMediaEls}</div>;
}

function renderAddress() {
  return (
    <div className='address'>
      2329 Nostrand Ave, Brooklyn, NY 11210 {'\n'}
      Office: (718) 377-7900 {'\n'}
      NMLS ID: 2212 {'\n'}
      FM HOME LOANS, LLC {'\n'}
    </div>
  );
}

function renderEqualBBB() {
  return (
    <>
      <img
        className='equal-housing'
        src={equalHousingImg}
        alt='equal housing opportunity'
      />
      <img className='bbb' src={bbbImg} alt='bbb' />
      <div className='bbb-info'>
        BBB Rating: A+ {'\n'}
        As of 11/12/2021 {'\n'}
        <Link
          href='http://www.bbb.org/new-york-city/business-reviews/mortgage-brokers/fm-home-loans-llc-in-brooklyn-ny-162076/#bbbonlineclick'
          target='_blank'
          rel='noopener noreferrer'
        >
          View Profile
        </Link>
      </div>
    </>
  );
}

function renderContactInfo() {
  return (
    <div className='contact-info'>
      <Link href='https://www.fmm.com/locations'>Contact Us</Link> |{' '}
      <Link href='https://www.fmm.com/privacy-policy'>Privacy Policy</Link> |{' '}
      <Link href='https://www.fmm.com/licensing-information'>
        Licensing Information
      </Link>
    </div>
  );
}

export default Footer;
