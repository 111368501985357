import React, { useEffect } from 'react';
import { useIsLoanOfficer } from 'src/Auth';
import { useIsWorking, useLoanApplication, useLoanOfficer } from 'src/hooks';
import defaultloimage from 'src/assets/img/defaultloimage.png';
import Icon from '@mdi/react';
import { mdiCellphone, mdiEmail } from '@mdi/js';
import { useDispatch } from 'react-redux';
import { getLoanOfficerById, getLoanOfficerFromLoan } from 'src/actions';
import './index.scss';
import Loading from 'src/components/Loading';

export const LoanOfficerCard = ({ loanGuid, referralTokenSearch }) => {
  const dispatch = useDispatch();
  const loanOfficer = useLoanOfficer();
  const loading = useIsWorking('fetch-lo-info');
  const loanApplication = useLoanApplication(loanGuid);
  const isLoanOfficer = useIsLoanOfficer();

  useEffect(() => {
    if (loanApplication?.loanOfficerId) {
      dispatch(
        getLoanOfficerById(loanApplication.loanOfficerId, {
          licenses: true,
        }),
      );
    }
    if (isLoanOfficer && loanGuid) {
      dispatch(getLoanOfficerFromLoan(loanGuid));
    }
  }, [dispatch, loanApplication?.loanOfficerId, isLoanOfficer, loanGuid]);

  const { email, phone, picture, NMLS, Found } = loanOfficer;
  const isWorking = loading || (!loanApplication && !isLoanOfficer);
  // if (session.isLoanOfficer) return null;
  if (!isWorking && (!email || (referralTokenSearch && !Found))) {
    return (
      <div style={{ color: 'red', lineHeight: '60px' }}>
        Loan Officer not found!
      </div>
    );
  }
  if (email + phone + NMLS + picture === '') return null;
  return (
    <div className='loan-officer-card flex-container'>
      {isWorking ? (
        <Loading label='Loading LO' horizontal />
      ) : (
        <>
          <img
            src={
              picture && picture.replace('data:image;base64,', '').length
                ? picture
                : defaultloimage
            }
            alt='Loan officer'
          />
          <div className='flex-container flex-center flex stack'>
            <span>
              <Icon path={mdiEmail} size={0.7} />
              {email}
            </span>
            {phone && (
              <span>
                <Icon path={mdiCellphone} size={0.7} />
                {phone}
              </span>
            )}
            <span>
              <strong>NMLS ID</strong> {NMLS || 'Unknown'}
            </span>
          </div>
        </>
      )}
    </div>
  );
};
