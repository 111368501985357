import CinchLogo from 'src/assets/img/Cinch-Logos-03-noMargins-transparent.svg';
import MrCapitalLogo from 'src/assets/img/MRCapitalLogos.svg';

const logo = window.location.origin.includes('mr.fmm.com')
  ? MrCapitalLogo
  : CinchLogo;
export default logo;

function changeFaviconAndTitle (src) {
  const link = document.createElement('link');
  const oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = src;
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);

  const title = document.createElement('title');
  const oldTitle = document.getElementById('dynamic-title');
  title.innerHTML = 'MR Capital Group';
  if (oldTitle) {
    document.head.removeChild(oldTitle);
  }
  document.head.appendChild(title);
}

if (window.location.origin.includes('mr.fmm.com')) {
  changeFaviconAndTitle(window.location.origin + '/Images/mrcapitallogos.ico');
}
