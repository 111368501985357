import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { mdiBug, mdiSend } from '@mdi/js';
import Icon from '@mdi/react';
import LogRocket from 'logrocket';
import React, { useState } from 'react';
import { submitBugReport } from 'src/actions/bugReport';
import { delay } from 'src/util';

const BugReport = ({ open, onClose }) => {
  const [issue, setIssue] = useState('');
  const [working, setWorking] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleBugReport = async () => {
    setWorking(true);
    const bugReportDetail = {
      logRocketSessionUrl: LogRocket.sessionURL,
      description: issue,
    };

    await submitBugReport(bugReportDetail);
    setSubmitted(true);
    await delay(2000);
    setWorking(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Icon path={mdiBug} />
        Report Issue
      </DialogTitle>
      <DialogContent>
        {submitted ? (
          <DialogContentText>Report Submitted!</DialogContentText>
        ) : (
          <TextField
            placeholder='Please describe your issue'
            onChange={(e) => setIssue(e?.target?.value)}
            multiline
            rows={4}
            label='Description'
            fullWidth
            variant='standard'
          />
        )}
      </DialogContent>
      <DialogActions>
        {!submitted && (
          <Button
            onClick={async () => {
              await handleBugReport();
              await onClose();
            }}
            size='small'
            endIcon={working ? <CircularProgress /> : <Icon path={mdiSend} />}
            variant='contained'
            color='primary'
          >
            Submit issue
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BugReport;
