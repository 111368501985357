import {
  OtherLiability,
  OtherLiabilityActionType,
} from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/OtherLiabilities/store/types';

type OtherLiabilityStore = OtherLiability[];

const defaultState: OtherLiabilityStore = [];

const reducer = (
  state: OtherLiabilityStore = defaultState,
  { type, data }: { type: OtherLiabilityActionType; data: any },
): OtherLiabilityStore => {
  switch (type) {
    // @ts-ignore
    case 'clear_loan_data':
      return defaultState;
    case OtherLiabilityActionType.SET: {
      const items = [...state];
      for (const item of data) {
        const matchIndex = state.findIndex((x) => x.id === item.id);
        if (matchIndex > -1) {
          items.splice(matchIndex, 1, {
            ...items[matchIndex],
            ...item,
          });
        } else {
          items.push(item);
        }
      }
      return items;
    }
    case OtherLiabilityActionType.UPDATE: {
      const matchIndex = state.findIndex((x) => x.id === data.id);
      const items = [...state];

      items.splice(matchIndex, 1, {
        ...items[matchIndex],
        ...data,
      });
      return items;
    }
    case OtherLiabilityActionType.ADD: {
      const items = [...state, data];
      return items;
    }
    case OtherLiabilityActionType.DELETE: {
      const matchIndex = state.findIndex((x) => x.id === data);
      const items = [...state];
      items.splice(matchIndex, 1);
      return items;
    }
    default:
      return state;
  }
};

export default reducer;
