const defaultState = {};

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_lo_data':
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};

export default reducer;
