import { useSelector } from 'react-redux';
import { useLoan, useLoanApplication } from 'src/hooks';
import { useIsLoanOfficer } from 'src/Auth';
import { LoanApplicationStatus } from 'src/types/BorrowerLoanApplication';

/**
 * @returns {boolean} Whether the loan forms are readonly (loan locked or app complete)
 */
export const useFormsDisabled = () => {
  const isLocked = useSelector((store) => store.app.loanLockedByOther);
  const appComplete = useIsAppComplete();
  return appComplete || isLocked || false;
};

/**
 * @returns {boolean} Whether the loan forms are readonly (loan locked or app complete or (not the primary borrower pair and not loan officer))
 */
export const useLoanFormsDisabled = () => {
  const isFormDisabled = useFormsDisabled();
  const isPrimaryApplicant = useIsPrimaryBorrower();
  const isLoanOfficer = useIsLoanOfficer();
  return isFormDisabled || (!isPrimaryApplicant && !isLoanOfficer) || false;
};

/**
 * @returns {boolean} Whether the loan applicant is the primary borrower of the loan
 */
export const useIsPrimaryBorrower = () => {
  const loan = useLoan();
  const loanApp = useLoanApplication(loan?.id ?? '');
  const isPrimaryApplicant = loanApp?.isPrimaryApplicant ?? false;
  return isPrimaryApplicant;
};

export const useIsAppComplete = () => {
  const loan = useLoan();
  const loanApp = useLoanApplication(loan?.id ?? '');
  return (
    loanApp !== undefined &&
    (loanApp.status !== LoanApplicationStatus.InProgress || loanApp.isComplete)
  );
};
