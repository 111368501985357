import axios from 'axios';

const axiosLogicApps = axios.create({
  transformRequest: (data, headers) => {
    // remove the default auth header meant for Cinch API
    delete headers.common.Authorization;
    // return the data or POST will not send a body
    return data;
  },
});

export const getSubjectCountyFromZip = async (zip) => {
  // 5 digit and 9 digit zip codes (e.g. 12345 vs 12345-6789)
  if (zip.length === 5 || zip.length === 10) {
    return axiosLogicApps
      .post(
        'https://prod-05.eastus.logic.azure.com:443/workflows/a8dc0b7991124a43b997e6a089f75cda/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=bY6TlRByCeGo6PVshJFHMHsccDrI8dpzEjxXqh6K9Vg',
        zip,
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log('Logic app county get error', error);
        return null;
      });
  } else {
    return null;
  }
};
