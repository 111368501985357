import { LoanActionType } from 'src/components/Loan/Application/store/actionTypes';
import { Loan } from 'src/types/loan';
import { mergeDeep } from 'src/util';

// @ts-ignore
const defaultState: Loan = {};

const reducer = (
  state: Loan = defaultState,
  { type, data }: { type: LoanActionType; data: any },
): Loan => {
  switch (type) {
    case LoanActionType.UPDATE:
      return mergeDeep(state, data);
    // @ts-ignore
    case 'clear_loan_data':
      return defaultState;
    default:
      return state;
  }
};

export default reducer;
