import * as y from 'yup';
import { BorrowerOrCoBorrowerType } from 'src/types';
import { UnitType } from 'src/types/property';

export enum ResidencyType {
  Current = 'Current',
  Prior = 'Prior',
}

export enum ResidencyBasisType {
  NoPrimaryHousingExpense = 'NoPrimaryHousingExpense',
  Rent = 'Rent',
  Own = 'Own',
}

export const ResidenceContract = y.object({
  id: y.string().uuid(),
  residencyType: y.mixed<ResidencyType>().oneOf(Object.values(ResidencyType)),
  residencyBasisType: y
    .mixed<ResidencyBasisType>()
    .oneOf(Object.values(ResidencyBasisType))
    .required(),
  rent: y.number().optional().nullable(true).when('residencyBasisType', {
    is: ResidencyBasisType.Rent,
    then: y.number().positive().required(),
  }),
  addressStreetLine1: y.string().required(),
  addressCity: y.string().required(),
  addressState: y.string().required(),
  addressPostalCode: y.string().required(),
  addressCounty: y.string().required(),
  durationTermMonths: y.number().required(),
  durationTermYears: y.number().required(),
  addressUnitDesignatorType: y
    .mixed<UnitType>()
    .oneOf(Object.values(UnitType))
    .optional(),
  addressUnitIdentifier: y.string().optional(),
});

export const StoreResidenceContract = ResidenceContract.shape({
  owner: y
    .mixed<BorrowerOrCoBorrowerType>()
    .oneOf(Object.values(BorrowerOrCoBorrowerType)),
});

/**
 * A borrower or coborrower residence
 */
export interface Residence extends y.Asserts<typeof ResidenceContract> {}
export interface StoreResidence
  extends y.Asserts<typeof StoreResidenceContract> {}

export enum ResidenceActionType {
  SET = 'set_residences',
  ADD = 'add_residence',
  DELETE = 'delete_residence',
  UPDATE = 'update_residence',
}

export const borrowerResidenceNewValidationErrorMap = {
  _entityName: 'Residence',
  borrower: {
    'home-address': {
      currentApplication: {
        borrower: {
          residences: {
            residenceTimeRequired:
              'Please enter more than 2 years of residences',
            '{0}': {
              residencyBasisType: 'Please select if your residence is owned',
              rent: 'Please enter in the amount of rent',
              addressStreetLine1: 'Please enter your street address',
              addressCity: 'Please enter your city',
              addressState: 'Please enter your state',
              addressPostalCode: 'Please enter your zip code',
              addressCounty:
                'Please enter a valid zip code to select your county',
              durationTermMonths: 'Please enter the residence duration months',
              durationTermYears: 'Please enter the residence duration years',
            },
          },
        },
      },
    },
  },
};

export const coborrowerResidenceNewValidationErrorMap = {
  _entityName: 'Residence',
  coborrower: {
    'home-address': {
      currentApplication: {
        coBorrower: {
          residences: {
            residenceTimeRequired:
              'Please enter more than 2 years of residences',
            '{0}': {
              residencyBasisType: 'Please select if your residence is owned',
              rent: 'Please enter in the amount of rent',
              addressStreetLine1:
                "Please enter your coborrower's street address",
              addressCity: "Please enter your coborrower's city",
              addressState: "Please enter your coborrower's state",
              addressPostalCode: "Please enter your coborrower's zip code",
              addressCounty:
                'Please enter a valid zip code for your coborrower to select their county',
              durationTermMonths:
                "Please enter the coborrower's residence duration months",
              durationTermYears:
                "Please enter the coborrower's residence duration years",
            },
          },
        },
      },
    },
  },
};
