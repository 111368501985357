import { apiAxios } from 'src/util';

interface BugReportDetail {
  logRocketSessionUrl: string;
  description: string;
}

export const submitBugReport = async (bugReportDetail: BugReportDetail) => {
  await apiAxios.post('services/bug-report', bugReportDetail);
};
