import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { Component } from 'react';
import './index.scss';

export default class ErrorBoundary extends Component {
  state = {
    error: null,
    info: '',
    isChunkLoadError: false,
  };

  static getDerivedStateFromError (error) {
    return {
      error,
      isChunkLoadError: error.toString().startsWith('ChunkLoadError:'),
    };
  }

  componentDidCatch (error, errorInfo) {
    console.error('[errorboundary]', error, errorInfo);
    this.setState({
      info: errorInfo,
    });
  }

  render () {
    return (
      <>
        {this.state.error && !this.state.isChunkLoadError && (
          <div className='error-boundary flex-container flex-center'>
            <h1>Something is broken</h1>
            <h2>{this.state.error.message}</h2>
            {process.env.NODE_ENV === 'development' && (
              <p>{this.state.info.componentStack.replace(/^\s*\n/, '')}</p>
            )}
          </div>
        )}
        <Dialog open={Boolean(this.state.error && this.state.isChunkLoadError)}>
          <DialogTitle>Something went wrong!</DialogTitle>
          <DialogContent style={{ paddingTop: 0 }}>
            <DialogContentText>
              Part of this page could not be loaded.
              <br />
              Cinch may have been updated - please refresh the page to use the
              latest version of the app.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => window.location.reload()} autoFocus>
              Refresh
            </Button>
          </DialogActions>
        </Dialog>
        {!this.state.error && this.props.children}
      </>
    );
  }
}
