import { Dispatch } from 'redux';
import { workstate } from 'src/actions';
import {
  Asset,
  AssetActionType,
} from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Assets/store/types';
import { Guid } from 'src/types';
import { apiAxios, hasAnyChanges, mergeDeep } from 'src/util';

export const createAsset =
  (loanId: Guid, applicationId: Guid, data?: Asset) => (dispatch: Dispatch) => {
    return workstate(dispatch, 'create-asset', async () => {
      const res = await apiAxios.post(
        `loans/${loanId}/applications/${applicationId}/assets`,
        data,
      );

      dispatch({
        type: AssetActionType.ADD,
        data: res.data,
      });
    });
  };

export const updateAsset =
  (loanId: Guid, applicationId: Guid, assetId: string, data: Asset) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'update-asset', async () => {
      if (!hasAnyChanges(data)) return;
      const res = await apiAxios.patch(
        `loans/${loanId}/applications/${applicationId}/assets/${assetId}`,
        data,
      );

      dispatch({
        type: AssetActionType.UPDATE,
        data: mergeDeep(data, res.data),
      });
    });
  };

export const deleteAsset =
  (loanId: Guid, applicationId: Guid, assetId: string) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'delete-asset', async () => {
      await apiAxios.delete(
        `loans/${loanId}/applications/${applicationId}/assets/${assetId}`,
      );

      dispatch({
        type: AssetActionType.DELETE,
        data: assetId,
      });
    });
  };
