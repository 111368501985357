import * as y from 'yup';

export enum LoanProductDataGsePropertyType {
  Attached = 'Attached',
  Condominium = 'Condominium',
  Cooperative = 'Cooperative',
  Detached = 'Detached',
  HighRiseCondominium = 'HighRiseCondominium',
  ManufacturedHousing = 'ManufacturedHousing',
  PUD = 'PUD',
  DetachedCondo = 'DetachedCondo',
  ManufacturedHomeCondoPUDCoOp = 'ManufacturedHomeCondoPUDCoOp',
  MHSelect = 'MHSelect',
  MHAdvantage = 'MHAdvantage',
}

export const LoanProductDataContract = y.object({
  gsePropertyType: y
    .mixed<LoanProductDataGsePropertyType>()
    .oneOf(Object.values(LoanProductDataGsePropertyType)),
  fnmProductPlanIdentifier: y
    .string()
    .optional()
    .when('$loan', (loan, schema) => {
      if (loan?.loanAmortizationType === 'AdjustableRate') {
        return schema.required();
      }
      return schema;
    }),
});

export interface LoanProductData
  extends y.Asserts<typeof LoanProductDataContract> {}

export const loanProductDataNewValidationErrorMap = {
  _entityName: 'LoanProductData',
  loan: {
    'about-property': {
      loanProductData: {
        gsePropertyType: 'Please select the property type',
      },
    },
  },
};
