import * as y from 'yup';
import { BorrowerType } from 'src/types';

enum GiftGrantAssetType {
  GiftOfCash = 'GiftOfCash',
  GiftOfPropertyEquity = 'GiftOfPropertyEquity',
  Grant = 'Grant',
}

enum GiftGrantSource {
  Employer = 'Employer',
  CommunityNonprofit = 'CommunityNonprofit',
  FederalAgency = 'FederalAgency',
  Institutional = 'Institutional',
  Lender = 'Lender',
  LocalAgency = 'LocalAgency',
  Other = 'Other',
  NonParentRelative = 'NonParentRelative',
  Parent = 'Parent',
  Relative = 'Relative',
  ReligiousNonprofit = 'ReligiousNonprofit',
  StateAgency = 'StateAgency',
  UnrelatedFriend = 'UnrelatedFriend',
  UnmarriedPartner = 'UnmarriedPartner',
}

export const GiftGrantContract = y
  .object({
    id: y.string().uuid(),
    // entity with holder
    holderAddressCity: y.string().optional(),
    holderAddressPostalCode: y.string().optional(),
    holderAddressState: y.string().optional(),
    holderAddressStreetLine1: y.string().optional(),
    holderComments: y.string().optional(),
    holderEmail: y.string().optional(),
    holderFax: y.string().optional(),
    holderName: y.string().optional(),
    holderPhone: y.string().optional(),
    attention: y.string().optional(),
    // entity with title
    title: y.string().required(),
    titlePhone: y.string().optional(),
    titleFax: y.string().optional(),
    // giftgrant-specific
    assetType: y
      .mixed<GiftGrantAssetType>()
      .oneOf(Object.values(GiftGrantAssetType)),
    source: y.mixed<GiftGrantSource>().oneOf(Object.values(GiftGrantSource)),
    otherSourceDescription: y.string().optional(),
    amount: y.number(),
    owner: y.mixed<BorrowerType>().oneOf(Object.values(BorrowerType)),
    printAttachmentIndicator: y.boolean().optional(),
    printUserNameIndicator: y.boolean().optional(),
    printUserJobTitleIndicator: y.boolean().optional(),
    depositedIndicator: y.boolean().optional(),
    giftOrGrantDate: y.string().optional(),
  })
  .strict();

/**
 * An GiftGrant entity describes a gift or grant asset that the borrower or coborrower holds.
 */
export interface GiftGrant extends y.Asserts<typeof GiftGrantContract> {}

export enum GiftGrantActionType {
  SET = 'set_giftsgrants',
  ADD = 'add_giftgrant',
  UPDATE = 'update_giftgrant',
  DELETE = 'delete_giftgrant',
}

export const giftGrantNewValidationErrorMap = {
  _entityName: 'GiftGrant',
  'assets-liabilities': {
    'gifts-grants': {
      currentApplication: {
        giftsGrants: {
          '{0}': {
            title: 'Please enter the gift/grant title',
            assetType: 'Please enter the gift/grant type',
            source: 'Please enter the gift/grant source',
            amount: 'Please enter the gift/grant amount',
          },
        },
      },
    },
  },
};

export const coborrowerGiftGrantNewValidationErrorMap = {
  _entityName: 'GiftGrant',
  'assets-liabilities': {
    'coborrower-gifts-grants': {
      currentApplication: {
        giftsGrants: {
          '{0}': {
            title: "Please enter your coborrower's gift/grant title",
            assetType: "Please enter your coborrower's gift/grant type",
            source: "Please enter your coborrower's gift/grant source",
            amount: "Please enter your coborrower's gift/grant amount",
          },
        },
      },
    },
  },
};
