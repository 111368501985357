const REMOVED_KEYS = ['ssn', 'taxIdentificationIdentifier', 'password'];
export const sanitizeRequest = (request) => {
  if (request.method === 'GET' || request.method === 'DELETE') return request;
  return sanitize(request);
};

export const sanitizeResponse = (response) => {
  return sanitize(response);
};

const sanitize = (response) => {
  const { body, headers } = response;
  const requestContentType = headers && (headers['Content-Type'] || '');
  const isUrlEncodedRequest = requestContentType?.includes('form-urlencoded');

  let parsedBody;
  try {
    if (isUrlEncodedRequest) {
      throw Error('Not implemented yet');
    }
    parsedBody = typeof body === 'string' ? JSON.parse(body) : body;
    searchAndExclude(parsedBody);
  } catch {
    return response;
  }
  response.body = parsedBody;
  return response;
};

const searchAndExclude = (body) => {
  if (body && Array.isArray(body)) {
    body.forEach(searchAndExclude);
  } else {
    for (const k in body) {
      if ({}.hasOwnProperty.call(body, k)) {
        const v = body[k];

        const isTypeValuePair = k === 'type' && 'value' in body;
        if (Boolean(v) && typeof v === 'object') {
          if (!isTypeValuePair) {
            searchAndExclude(v);
          }
        }

        if (isTypeValuePair) {
          tryMask(body, body.type, 'value');
        } else {
          tryMask(body, k);
        }
      }
    }
  }
};

const tryMask = (body, searchKey, maskKey) => {
  maskKey = maskKey || searchKey;
  const lowerCasedKey = maskKey.toLowerCase();
  const shouldMask = REMOVED_KEYS.some(
    (k) => lowerCasedKey.indexOf(k.toLowerCase()) > -1,
  );

  if (shouldMask) {
    body[maskKey] = '***';
  }
};
