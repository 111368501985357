/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import {
  useCurrentApplication,
  useLoan,
  useLoanFields,
  useResidences,
} from 'src/hooks';
import { useDispatch } from 'react-redux';
import {
  createResidence,
  updateResidence,
  updateApplicationBorrower,
} from 'src/actions';
import { fieldIds, cloneDeep } from 'src/util';
import { BorrowerOrCoBorrowerType } from 'src/types';

export const useSyncCoBorrowerAddress = () => {
  const dispatch = useDispatch();
  const loan = useLoan();
  const application = useCurrentApplication();
  const customFields = useLoanFields();
  const hasCoborrower = customFields[fieldIds.hasCoborrower] === 'true';
  const borrowerResidences = useResidences(BorrowerOrCoBorrowerType.Borrower);
  const coBorrowerResidences = useResidences(
    BorrowerOrCoBorrowerType.CoBorrower
  );

  const updateCoBorrowersResidence = async () => {
    if (
      !hasCoborrower ||
      customFields?.[fieldIds.sameAddressAsBorrower] !== 'Y'
    ) {
      return;
    }
    const borrowerResidence = cloneDeep(borrowerResidences?.find(
      (residence) => residence.residencyType === 'Current'
    ));
    if (!borrowerResidence) {
      return;
    }
    delete borrowerResidence.id;
    if (Object.keys(borrowerResidence).length === 0) {
      return;
    }

    borrowerResidence.owner = BorrowerOrCoBorrowerType.CoBorrower;
    const coBorrowerResidence = coBorrowerResidences?.find(
      (residence) => residence.residencyType === 'Current'
    );
    if (!coBorrowerResidence) {
      await dispatch(
        createResidence(
          loan.id,
          application.id,
          BorrowerOrCoBorrowerType.CoBorrower,
          borrowerResidence
        )
      );
    } else {
      await dispatch(
        updateResidence(
          loan.id,
          application.id,
          BorrowerOrCoBorrowerType.CoBorrower,
          coBorrowerResidence.id,
          borrowerResidence
        )
      );
    }
  };

  const updateCoBorrowersMailingAddress = async () => {
    if (
      !hasCoborrower ||
      customFields?.[fieldIds.sameMailingAddressAsBorrower] !== 'Y'
    ) {
      return;
    }

    let updateFields = {};
    if (application?.borrower?.mailingAddressSameAsPresentIndicator) {
      updateFields = {
        mailingAddressSameAsPresentIndicator: true,
      };
    }
    else if (application?.borrower?.mailingAddress) {
      updateFields = {
        mailingAddress: application?.borrower?.mailingAddress,
      };
    }
    else {
      return;
    }
    
    await dispatch(
      updateApplicationBorrower(
        loan.id,
        application.id,
        BorrowerOrCoBorrowerType.CoBorrower,
        updateFields
      )
    );
  };

  useEffect(() => {
    (async () => {
      await updateCoBorrowersResidence();
      await updateCoBorrowersMailingAddress();
    })();
  }, []);
};
