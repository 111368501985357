import * as y from 'yup';
import {
  ApplicationContract,
  CoborrowerApplicationContract,
} from 'src/types/application';
import { FieldContract, ValidationFieldContract } from 'src/types/field';
import { LoanProductDataContract } from 'src/types/loanproductdata';
import { PropertyContract } from 'src/types/property';
import { PrequalificationContract } from 'src/types/prequalification';

export const LoanContract = y.object({
  id: y.string().uuid(),
  referralSource: y.string().optional(),
  expectedBorrowerCount: y.number().optional(),
  currentApplicationIndex: y.number().required(),
  purchasePriceAmount: y
    .number()
    .optional()
    .test(
      'key:idealPurchasePrice',
      'Please enter your desired loan amount',
      (val, ctx) => {
        // @ts-ignore
        const purpose = ctx.loan?.property?.loanPurposeType;
        if (purpose === 'PreApproval') {
          return (val || 0) > 0;
        }
        return true;
      },
    )
    .when('$loan', (loan, schema) => {
      const purpose = loan?.property?.loanPurposeType;
      if (!purpose?.includes('Refinance') && purpose !== 'PreApproval') {
        return schema.required();
      }
      return schema;
    }),
  proposedDuesAmount: y.number().optional(),
  proposedRealEstateTaxesAmount: y.number().optional(),
  proposedHazardInsuranceAmount: y.number().optional(),
  borrowerRequestedLoanAmount: y
    .number()
    .optional()
    .when('$loan', (loan, schema) => {
      if (loan?.property?.loanPurposeType?.includes('Refinance')) {
        return schema.required().positive();
      }
      return schema;
    }),
  downPayment: y.object({
    amount: y.number().optional(),
    sourceDescription: y
      .string()
      .optional()
      .when('$loan', (loan, schema) => {
        if (!loan?.property?.loanPurposeType?.includes('Refinance')) {
          return schema.required();
        }
        return schema;
      }),
  }),
  downPaymentPercent: y.number().optional(),
  mortgageType: y.string().required(),
  loanAmortizationType: y.string().required(),
  fieldLockData: y.array(y.string()).optional(),
  loanAmortizationTermMonths: y
    .number()
    .optional()
    .when('loanAmortizationType', {
      is: (val) => val !== 'AdjustableRate',
      then: y.number().positive().required(),
    }),
  applications: y.array(CoborrowerApplicationContract),
  property: PropertyContract,
  customFields: y.array(FieldContract),
  loanProductData: LoanProductDataContract,
  prequalification: PrequalificationContract.optional(),
});

export const StoreLoanContract = LoanContract.omit([
  'applications',
  'customFields',
]);

export const ValidationLoanContract = LoanContract.shape({
  currentApplication: ApplicationContract,
  customFields: ValidationFieldContract,
});

export interface Loan extends y.Asserts<typeof LoanContract> {}
export interface StoreLoan extends y.Asserts<typeof StoreLoanContract> {}
export interface ValidationLoan
  extends y.Asserts<typeof ValidationLoanContract> {}

export const loanNewValidationErrorMap = {
  _entityName: 'Loan',
  loan: {
    type: {
      mortgageType: 'Please select the mortgage type',
    },
    'loan-amount': {
      borrowerRequestedLoanAmount: 'Please enter the requested loan amount',
      prequalification: {
        cashOutBalance: 'Please enter how much cash you would like to take out',
      },
    },
    'borrow-amount': {
      downPayment: 'Please enter the down payment',
      downPaymentPercent: 'Please enter the down payment percent',
      purchasePriceAmount: 'Please enter your ideal purchase price',
    },
    terms: {
      loanAmortizationType:
        'Please select the type of terms are you looking for in your loan',
    },
    'years-to-pay': {
      loanAmortizationTermMonths: 'Please select the amortization term',
    },
    'property-usage': {
      currentApplication: {
        propertyUsageType: 'Please select how you will be using the property',
      },
    },
    'target-market': {
      idealPurchasePrice: 'Please enter your ideal purchase price',
    },
    'rate-adjustment': {
      loanProductData: {
        fnmProductPlanIdentifier:
          'Please select when you would like the rate to begin adjusting',
      },
    },
    'payment-plan': {
      downPayment: {
        sourceDescription:
          'Please select how you are planning to pay your down payment',
      },
    },
  },
};
