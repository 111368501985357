const defaultState = {};

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_form_isdirty': {
      const forms = { ...state };
      forms[data.formId] = data.isDirty;
      return forms;
    }
    default:
      return state;
  }
};

export default reducer;
