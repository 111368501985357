import auth0 from 'auth0-js';
import { deleteLoanLock, refreshLoanLock } from 'src/actions/loan';
import { setWorking, setData } from './actions';
import Axios from 'axios';
import env from 'src/env';
import { apiAxios } from 'src/util';
import { clearLoan } from 'src/actions';

class Auth {
  auth0;
  accessToken;
  idToken;
  expiresAt;
  expiresIn;
  idTokenExpUtc;
  userFullName;
  userEmail;
  isLoanOfficer;
  loEncompassId;
  isAdmin;
  auth0UserId;
  _dispatch;
  needsDispatch = true;

  constructor (store) {
    this.reduxStore = store;
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.setProfile = this.setProfile.bind(this);
    this.getSecondsLeft = this.getSecondsLeft.bind(this);
    this.parseJwt = this.parseJwt.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
    this.refreshLock = this.refreshLock.bind(this);
    this.deleteLock = this.deleteLock.bind(this);
    // this.setAppComplete = this.setAppComplete.bind(this);

    this.auth0 = new auth0.WebAuth({
      domain: env.AUTH_DOMAIN,
      clientID: store.getState().app?.clientID || env.CLIENT_ID,
      redirectUri: window.location.origin + '/callback',
      responseType: 'token id_token',
      scope: 'openid',
    });
    if (process.env.NODE_ENV === 'development') window.auth0Client = this;
  }

  /**
   * @param {function(action)} dispatchFunction
   */
  set dispatch (dispatchFunction) {
    // this is only set once when the store is created, and is a hack to make the state update
    if (!this.needsDispatch) return;
    this.needsDispatch = false;
    this._dispatch = dispatchFunction;

    this._dispatch(
      setData({
        logout: this.logout,
        login: this.login,
        handleAuthentication: this.handleAuthentication,
        refreshLock: this.refreshLock,
        deleteLock: this.deleteLock,
        refreshToken: this.refreshToken,
        startLockClock: this.startLockClock,
        stopLockClock: this.stopLockClock,
        // setAppComplete: this.setAppComplete,
      })
    );
    (async function () {
      try {
        this._dispatch(setWorking(true));
        const session = await this.checkSession();
        this._dispatch(
          setData({
            session: getPropertiesWithoutFunctions(session),
            error: null,
          })
        );

        if (session) {
          const profile = await this.setProfile();
          this._dispatch(
            setData({
              user: profile,
            })
          );
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this._dispatch(setWorking(false));
        this.reduxStore.dispatch({ type: 'app_ready' });
      }
    }.bind(this)());
  }
  /*
  setAppComplete (appComplete) {
    this.appComplete = appComplete;
    this._dispatch(
      setData({
        session: getPropertiesWithoutFunctions(this),
      }),
    );
  } */

  async checkSession () {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) {
          reject(err);
        }
        if (authResult && authResult.accessToken && authResult.idToken) {
          const session = this.setSession(authResult, true);
          resolve(session);
        } else resolve(null);
      });
    });
  }

  login (email, password, cb, isLo) {
    if (isLo) {
      this.auth0.authorize({
        redirectUri: env.LO_URL + '/callback',
      });
    } else {
      this.auth0.login(
        {
          email,
          password,
          realm: 'Username-Password-Authentication',
        },
        (err) => {
          if (cb) {
            cb(err);
          }
        }
      );
    }
  }

  handleAuthentication () {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash(
        {
          hash: window.location.hash,
        },
        (err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.setSession(authResult);
            resolve(this);
          } else if (err) {
            // should redirect users to a 403 page or something similar
            console.error(err);
            window.location.href = '/login';
            // eslint-disable-next-line no-alert
            alert(
              `Error: ${err.error} - ${err.errorDescription}. Check the console for further details.`
            );
            reject(err);
          }
        }
      );
    });
  }

  getAccessToken () {
    return this.accessToken;
  }

  getIdToken () {
    return this.idToken;
  }

  setProfile () {
    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(this.accessToken, (err, profile) => {
        if (err) return reject(err);
        if (profile) {
          this.profile = profile;
          if (this._dispatch) {
            this._dispatch(
              setData({
                user: profile,
              })
            );
          }
          return resolve(profile);
        } else {
          return reject(err);
        }
      });
    });
  }

  setSession (authResult) {
    // Set the time that the access token will expire at
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;
    this.expiresIn = authResult.expiresIn;

    const token = authResult.idTokenPayload;
    Axios.defaults.headers.common.Authorization = `Bearer ${authResult.idToken}`;
    apiAxios.defaults.headers.common.Authorization = `Bearer ${authResult.idToken}`;
    this.idTokenExpUtc = new Date(token.exp * 1000);
    this.userFullName = token['https://apply.fmm.com/fullName'];
    /* this.appComplete =
      token['https://apply.fmm.com/appComplete'] === 'false'
        ? false
        : token['https://apply.fmm.com/appComplete'];
    this.pairIndex = token['https://apply.fmm.com/applicationIndex']
      ? +token['https://apply.fmm.com/applicationIndex']
      : 0; */
    // this.referralId = token['https://apply.fmm.com/referralId'];
    this.isLoanOfficer = token.sub.includes('waad');
    this.auth0UserId = token.sub;

    this.userEmail = token['https://apply.fmm.com/email'];

    if (this.isLoanOfficer) {
      this.userFullName = token['https://apply.fmm.com/name'];
    }

    /* if (!this.isLoanOfficer) {
      this.guidFromAuth0 = token['https://apply.fmm.com/loanGuid'];
    } */

    this._dispatch(
      setData({
        session: getPropertiesWithoutFunctions(this),
        isAuthenticated: this.isAuthenticated,
      })
    );

    return this;
  }

  // lock clock prevents the SQL server from deleting the lock while you are inside it
  startLockClock = (guid) => {
    this.lockClock = setInterval(() => {
      this.refreshLock(guid);
    }, 1000 * 60 * 2);
  };

  stopLockClock = (guid) => {
    clearInterval(this.lockClock);
    this.lockClock = null;
    this.deleteLock(guid);
  };

  refreshLock (guid) {
    this.reduxStore.dispatch(refreshLoanLock(guid));
  }

  deleteLock (guid) {
    const storeState = this.reduxStore.getState();
    if (!storeState.app.loanLockedByOther && guid) {
      this.reduxStore.dispatch(clearLoan());
      this.reduxStore.dispatch(deleteLoanLock(guid));
    }
  }

  refreshToken (guid) {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          const session = this.setSession(authResult, true);
          resolve(session);
        } else if (err) {
          this.logout(guid);
          // eslint-disable-next-line no-alert
          alert(
            `Could not get a new token (${err.error}: ${err.error_description}).`
          );
          return reject(err);
        }
      });
    });
  }

  logout (guid) {
    this.stopLockClock(guid);

    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;
    // this.setAppComplete(null);

    for (let i = 0; i < localStorage.length; ++i) {
      const k = localStorage.key(i);
      if (!k.startsWith('crinch.development')) localStorage.removeItem(k);
    }
    sessionStorage.clear();
    document.cookie.split(';').forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });

    this.auth0.logout({ returnTo: window.location.origin + '/login' });
  }

  isAuthenticated () {
    // Check whether the current time is past the
    // access token's expiry time
    const authenticated = new Date().getTime() < this.expiresAt;
    return authenticated && Boolean(this.idToken);
  }

  parseJwt (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  }

  getSecondsLeft () {
    if (!this.idToken) return 1200;
    return (this.idTokenExpUtc - new Date()) / 1000;
  }
}

const getPropertiesWithoutFunctions = (obj) => {
  if (!obj) return null;
  const props = {};
  for (const i in obj) {
    if (!(obj[i] instanceof Function)) props[i] = obj[i];
  }
  return props;
};

// const auth0Client = new Auth()
let auth0Client = null;

export const createStore = (store) => {
  auth0Client = new Auth(store);
  return auth0Client;
};

// export default auth0Client
