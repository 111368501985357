import { BorrowerType } from 'src/types';
import * as y from 'yup';

enum OtherIncomeSourceType {
  AccessoryUnitIncome = 'AccessoryUnitIncome',
  Alimony = 'Alimony',
  AutomobileAllowance = 'AutomobileAllowance',
  BoarderIncome = 'BoarderIncome',
  CapitalGains = 'CapitalGains',
  ChildSupport = 'ChildSupport',
  DefinedContributionPlan = 'DefinedContributionPlan',
  Disability = 'Disability',
  DividendsInterest = 'DividendsInterest',
  EmploymentRelatedAccount = 'EmploymentRelatedAccount',
  FosterCare = 'FosterCare',
  HousingAllowance = 'HousingAllowance',
  HousingChoiceVoucherProgram = 'HousingChoiceVoucherProgram',
  MortgageCreditCertificate = 'MortgageCreditCertificate',
  MortgageDifferential = 'MortgageDifferential',
  NonBorrowerHouseholdIncome = 'NonBorrowerHouseholdIncome',
  NotesReceivableInstallment = 'NotesReceivableInstallment',
  Other = 'Other',
  Pension = 'Pension',
  PublicAssistance = 'PublicAssistance',
  Royalties = 'Royalties',
  SeparateMaintenance = 'SeparateMaintenance',
  SocialSecurity = 'SocialSecurity',
  TemporaryLeave = 'TemporaryLeave',
  TipIncome = 'TipIncome',
  Trust = 'Trust',
  Unemployment = 'Unemployment',
  VABenefitsNonEducational = 'VABenefitsNonEducational',
}

export const OtherIncomeContract = y
  .object({
    id: y.string().uuid(),
    // entity with holder
    holderAddressCity: y.string().optional(),
    holderAddressPostalCode: y.string().optional(),
    holderAddressState: y.string().optional(),
    holderAddressStreetLine1: y.string().optional(),
    holderComments: y.string().optional(),
    holderEmail: y.string().optional(),
    holderFax: y.string().optional(),
    holderName: y.string().optional(),
    holderPhone: y.string().optional(),
    attention: y.string().optional(),
    // entity with title
    title: y.string().optional(),
    titlePhone: y.string().optional(),
    titleFax: y.string().optional(),
    // entity
    owner: y.mixed().oneOf(Object.values(BorrowerType)).optional(),
    otherIncomeSourceDate: y.string().optional(),
    printUserNameIndicator: y.boolean().optional(),
    printAttachmentIndicator: y.boolean().optional(),
    printUserJobTitleIndicator: y.boolean().optional(),
    foreignAddressIndicator: y.boolean().optional(),
    foreignIncomeIndicator: y.boolean().optional(),
    seasonalIncomeIndicator: y.boolean().optional(),
    description: y.mixed().oneOf(Object.values(OtherIncomeSourceType)),
    otherDescription: y.string().optional().when('description', {
      is: OtherIncomeSourceType.Other,
      then: y.string().required(),
    }),
    monthlyAmount: y.number().positive().required(),
    altId: y.string().optional(),
  })
  .strict();

/**
 * An OtherIncome (OtherIncomeSource) entity describes an income not from an Employment
 */
export interface OtherIncome extends y.Asserts<typeof OtherIncomeContract> {}

export enum OtherIncomeActionType {
  SET = 'set_other_incomes',
  ADD = 'add_other_income',
  UPDATE = 'update_other_income',
  DELETE = 'delete_other_income',
}

export const otherIncomeNewValidationErrorMap = {
  _entityName: 'OtherIncome',
  income: {
    'other-income': {
      currentApplication: {
        otherIncomeSources: {
          '{0}': {
            description: 'Please enter other income source',
            monthlyAmount: 'Please enter other income amount',
            otherDescription: 'Please enter other income description',
          },
        },
      },
    },
  },
};

export const coborrowerOtherIncomeNewValidationErrorMap = {
  _entityName: 'OtherIncome',
  income: {
    'coborrower-other-income': {
      currentApplication: {
        otherIncomeSources: {
          '{0}': {
            description: 'Please enter coborrower other income source',
            monthlyAmount: 'Please enter coborrower other income amount',
            otherDescription:
              'Please enter coborrower other income description',
          },
        },
      },
    },
  },
};
