import { workstate } from '.';
import { apiAxios } from 'src/util';

export const emailLoanOfficerToExitLoan = (data) => (dispatch) => {
  return workstate(dispatch, 'email-lo-loan-locked', async () => {
    return await apiAxios.post(
      `loan-officers/notify/loan-locked/${data.loanGuid}`,
      data,
    );
  });
};
