import React from 'react';
import axios from 'axios';
import { AnonymousAppConfigurationProvider } from '@fmm/react-app-configuration';
import FMLoader from 'src/components/FMLoader/index';
import { connect } from 'react-redux';
import { FEATURE_FLAGS } from 'src/util';

const defaults = {
  features: {
    [FEATURE_FLAGS.useEmailVerification]: true,
  },
};

const AnonFeatureFlags = ({ children, activeEnvironment }) => {
  return (
    <AnonymousAppConfigurationProvider
      env={activeEnvironment}
      options={{
        featureFlags: [FEATURE_FLAGS.useEmailVerification, FEATURE_FLAGS.useEconsent],
      }}
      blocking={false}
      defaults={defaults}
      axios={axios}
      fallback={<FMLoader backgroundColor='#ffffff' />}
    >
      {children}
    </AnonymousAppConfigurationProvider>
  );
};

const mapStateToProps = ({ app: { activeEnvironment } }) => {
  return {
    activeEnvironment,
  };
};

export const AnonFeatureFlagsProvider = connect(mapStateToProps)(
  AnonFeatureFlags,
);
