export enum LoanApplicationStatus {
  InProgress = 'InProgress',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Denied = 'Denied',
}

export interface BorrowerLoanApplication {
  status: LoanApplicationStatus;
  loanGuid: string;
  applicationGuid: string;
  applicationIndex?: number;
  propertyName?: string;
  loanAmount?: number;
  loanOfficerId: number;
  isComplete: boolean;
  isPrimaryApplicant: boolean;
  createdAt: string;
  primaryBorrowerName?: string;
  error?: string;
}
