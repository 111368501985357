import { VodContract } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Assets/store/types';
import { GiftGrantContract } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/GiftsGrants/store/types';
import { VolContract } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Liabilities/store/types';
import { OtherAssetContract } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/OtherAssets/store/types';
import { OtherLiabilityContract } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/OtherLiabilities/store/types';
import { ReoPropertyContract } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Properties/store/types';
import { OtherIncomeContract } from 'src/components/Loan/Application/Sections/IncomeInfo/Inputs/OtherIncomes/store/types';
import {
  BorrowerContract,
  MinimalBorrowerContract,
  MinimalCoborrowerContract,
  StoreBorrowerContract,
} from 'src/types/borrower';
import { Loan } from 'src/types/loan';
import { fieldIds } from 'src/util';
import * as y from 'yup';

export enum PropertyUsageType {
  PrimaryResidence = 'PrimaryResidence',
  SecondHome = 'SecondHome',
  Investor = 'Investor',
  FHASecondaryResidence = 'FHASecondaryResidence',
}

export const ApplicationContract = y.object({
  id: y.string().uuid(),
  legacyId: y.string().optional(),
  borrower: BorrowerContract,
  coBorrower: BorrowerContract,
  vods: y.array(VodContract),
  otherAssets: y.array(OtherAssetContract),
  vols: y.array(VolContract),
  otherLiabilities: y.array(OtherLiabilityContract),
  reoProperties: y.array(ReoPropertyContract),
  giftsGrants: y.array(GiftGrantContract),
  otherIncomeSources: y.array(OtherIncomeContract),
  propertyUsageType: y
    .mixed<PropertyUsageType>()
    .oneOf(Object.values(PropertyUsageType))
    .optional()
    .when('$loan', (loan: Loan, schema) => {
      if (!loan?.property?.loanPurposeType?.includes('Refinance')) {
        return schema.required();
      }
      if (
        loan?.customFields?.[fieldIds.refinancingCurrentProperty] !== 'true'
      ) {
        return schema.required();
      }
      return schema;
    }),
});

export const StoreApplicationContract = ApplicationContract.omit([
  'vods',
  'otherAssets',
  'vols',
  'otherLiabilities',
  'reoProperties',
  'giftsGrants',
  'otherIncomeSources',
]).shape({
  borrower: StoreBorrowerContract,
  coBorrower: StoreBorrowerContract,
});

export const CoborrowerApplicationContract = ApplicationContract.pick([
  'id',
  'borrower',
  'coBorrower',
]).shape({
  borrower: MinimalBorrowerContract,
  coBorrower: MinimalCoborrowerContract,
});

export interface Application extends y.Asserts<typeof ApplicationContract> {}
export interface StoreApplication
  extends y.Asserts<typeof StoreApplicationContract> {}
