import { Dispatch } from 'redux';
import { workstate } from 'src/actions';
import {
  Employment,
  EmploymentActionType,
} from 'src/components/Loan/Application/Sections/EmploymentInfo/Inputs/Employments/store/types';
import { BorrowerOrCoBorrowerType, Guid } from 'src/types';
import { apiAxios, hasAnyChanges, mergeDeep } from 'src/util';

export const createEmployment =
  (
    loanId: Guid,
    applicationId: Guid,
    applicantType: BorrowerOrCoBorrowerType,
    data?: Employment,
  ) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'create-employment', async () => {
      const res = await apiAxios.post(
        `loans/${loanId}/applications/${applicationId}/${applicantType.toLowerCase()}/employments`,
        data,
      );

      dispatch({
        type: EmploymentActionType.ADD,
        data: {
          ...res.data,
          owner: applicantType,
        },
      });
    });
  };

export const updateEmployment =
  (
    loanId: Guid,
    applicationId: Guid,
    applicantType: BorrowerOrCoBorrowerType,
    employmentId: string,
    data: Employment,
  ) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'update-employment', async () => {
      if (!hasAnyChanges(data)) return;
      const res = await apiAxios.patch(
        `loans/${loanId}/applications/${applicationId}/${applicantType.toLowerCase()}/employments/${employmentId}`,
        data,
      );

      dispatch({
        type: EmploymentActionType.UPDATE,
        data: mergeDeep(data, res.data),
      });
    });
  };

export const deleteEmployment =
  (
    loanId: Guid,
    applicationId: Guid,
    applicantType: BorrowerOrCoBorrowerType,
    employmentId: string,
  ) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'delete-employment', async () => {
      await apiAxios.delete(
        `loans/${loanId}/applications/${applicationId}/${applicantType.toLowerCase()}/employments/${employmentId}`,
      );

      dispatch({
        type: EmploymentActionType.DELETE,
        data: employmentId,
      });
    });
  };
