import * as y from 'yup';

export const PrequalificationScenarioContract = y.object({
  prequalificationScenarioIndex: y.number().optional(),
  appraisedValue: y.number().optional(),
  apr: y.number().optional(),
  cashToClose: y.number().optional(),
  closingCost: y.number().optional(),
  cltv: y.number().optional(),
  comments: y.string().optional(),
  creditScore: y.string().optional(),
  currentStatus: y.string().optional(),
  downPaymentAmount: y.number().optional(),
  downPaymentPercent: y.number().optional(),
  fhaUpfrontMiPremiumPercent: y.number().optional(),
  grossNegativeCashFlow: y.number().optional(),
  loanAmount: y.number().optional(),
  ltv: y.number().optional(),
  maximumDebt: y.number().optional(),
  maximumLoanAmount: y.number().optional(),
  miAndFundingFeeFinancedAmount: y.number().optional(),
  minimumIncome: y.number().optional(),
  monthlyPayment: y.number().optional(),
  prepaidItemsEstimatedAmount: y.number().optional(),
  qualBottomRatioPercent: y.number().optional(),
  qualTopRatioPercent: y.number().optional(),
  salesPrice: y.number().optional(),
  subordinateFin: y.number().optional(),
  totalBaseCost: y.number().optional(),
  totalCashAvailable: y.number().optional(),
  totalCashLeft: y.number().optional(),
  totalCosts: y.number().optional(),
  totalFinancing: y.number().optional(),
  totalHe: y.number().optional(),
  totalIncome: y.number().optional(),
  totalLoanAmount: y.number().optional(),
  totalOtherExpense: y.number().optional(),
  totalPaidOffMortgage: y.number().optional(),
  totalPaidOffOthers: y.number().optional(),
  totalPayments: y.number().optional(),
});

export const PrequalificationContract = y.object({
  afterTaxOwnMoSavings: y.number().optional(),
  afterTaxRentMoSavings: y.number().optional(),
  annualHomeManumberenance: y.number().optional(),
  avgMoPmtSavings: y.number().optional(),
  beforeTaxOwnMoPmt: y.number().optional(),
  beforeTaxRentMoPmt: y.number().optional(),
  cashOutBalance: y
    .number()
    .optional()
    .when('$loan', (loan, schema) => {
      if (loan?.property?.loanPurposeType === 'Cash-Out Refinance') {
        return schema.required();
      }
      return schema;
    }),
  combinedGain: y.number().optional(),
  costIncreasePerYear: y.number().optional(),
  downPaymentAmount: y.number().optional(),
  favorableOption: y.string().optional(),
  homeSellingPriceAfterYears: y.number().optional(),
  investmentGain: y.number().optional(),
  maxLoanLimit: y.number().optional(),
  maxPropValue: y.number().optional(),
  minusDownPmtAndClosingCosts: y.number().optional(),
  minusLoanBalance: y.number().optional(),
  monthlyBenefit: y.number().optional(),
  monthlyHomeAppreciation: y.number().optional(),
  monthlyHomeEquity: y.number().optional(),
  monthlyIncomeTax: y.number().optional(),
  monthlyInvestmentnumbererest: y.number().optional(),
  monthlySavings: y.number().optional(),
  numberOfMonths: y.string().optional(),
  percentAnnualHomeManumber: y.number().optional(),
  percentOfHomeAppreciation: y.number().optional(),
  percentOfIncomeTax: y.number().optional(),
  percentOfInvestmentnumbererest: y.number().optional(),
  prequalificationScenarios: y
    .array(PrequalificationScenarioContract)
    .optional(),
  qualificationStatus: y.string().optional(),
  rentalCost: y.number().optional(),
  rentersInsurance: y.number().optional(),
  totalBenefit: y.number().optional(),
  totalCashFlow: y.number().optional(),
  totalGain: y.number().optional(),
  totalHomeAppreciation: y.number().optional(),
  totalHomeEquity: y.number().optional(),
  totalHousingExpense: y.number().optional(),
  totalIncomeTax: y.number().optional(),
  totalInvestmentnumbererest: y.number().optional(),
  totalLiabilityPayment: y.number().optional(),
  totalLiabilityUnpaid: y.number().optional(),
  totalLoanSavings: y.number().optional(),
  totalOtherExpenses: y.number().optional(),
  totalOwnPmtOverYears: y.number().optional(),
  totalOwnTaxSavings: y.number().optional(),
  totalPaidOffBalance: y.number().optional(),
  totalPaidOffMonthly: y.number().optional(),
  totalPmtSavings: y.number().optional(),
  totalRentPmtOverYears: y.number().optional(),
  withinLimits1: y.string().optional(),
  withinLimits2: y.string().optional(),
  withinLimits3: y.string().optional(),
  withinLimits4: y.string().optional(),
  withinLimits5: y.string().optional(),
  withinLimits6: y.string().optional(),
  withinLimits7: y.string().optional(),
  withinLimits8: y.string().optional(),
  withinLimits9: y.string().optional(),
  yearsForComparison: y.number().optional(),
});

export interface Prequalification
  extends y.Asserts<typeof PrequalificationContract> {}
export interface PrequalificationScenario
  extends y.Asserts<typeof PrequalificationScenarioContract> {}
