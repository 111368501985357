import React, { createContext, useContext, useRef, useState } from 'react';
import BugReport from 'src/components/BugReport';
const BugContext = createContext(Promise.reject);

export const BugProvider = ({ children }) => {
  const [state, setState] = useState(false);
  const awaitingRef = useRef<any>();

  const open = (): Promise<any> => {
    setState(true);
    return new Promise((resolve, reject) => {
      awaitingRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingRef.current) {
      awaitingRef.current.resolve(false);
    }
    setState(false);
  };

  return (
    <>
      <BugContext.Provider value={open}>{children}</BugContext.Provider>
      <BugReport open={Boolean(state)} onClose={handleClose} />
    </>
  );
};

export const useBugReport = () => useContext(BugContext);
