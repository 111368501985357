export default {
  name: 'staging',
  hostnames: [
    'cinchborrowerstaging.azurefd.net',
    'cinchborrowerstaging.fmm.com',
  ],
  values: {
    FUNCTION_URL:
      'https://func-cinch-serverside-staging.azurewebsites.net/api/',
    API_URL: 'https://app-cinch-borrower-staging.azurewebsites.net/api/',
    AUTH_DOMAIN: 'logintest.fmm.com',
    CLIENT_ID: 'sMZyVF6fTqPwyTJqGXmLGXzsppByYifV',
    AI_INSTRUMENTATION_KEY: '8e60ab53-730f-477c-b154-f3aa67606265',
    DEPLOY_ENV: 'staging',
    LO_URL: 'https://cinchstaging.fmm.com',
    LOGROCKET_APPID: 'fmm/cinch-borrower-staging',
  },
};
