import { compareAsc, parseISO } from 'date-fns';

const defaultState = [];

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_messages': {
      const messages = [...state];
      for (const message of data) {
        const matchIndex = state.findIndex((x) => x.id === message.id);
        if (matchIndex > -1) {
          messages.splice(matchIndex, 1, {
            ...messages[matchIndex],
            ...message,
          });
        } else {
          messages.push(message);
        }
      }
      return messages.sort((a, b) =>
        compareAsc(parseISO(a.createdAt), parseISO(b.createdAt)),
      );
    }
    case 'update_message': {
      const matchIndex = state.findIndex((x) => x.id === data.id);
      const messages = [...state];

      messages.splice(matchIndex, 1, {
        ...messages[matchIndex],
        ...data,
      });
      return messages;
    }
    case 'add_message': {
      const messages = [...state, data];
      return messages;
    }
    case 'remove_message': {
      const matchIndex = state.findIndex((x) => x.id === data);
      const messages = [...state];
      messages.splice(matchIndex, 1);
      return messages;
    }
    default:
      return state;
  }
};

export default reducer;
