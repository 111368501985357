const defaultState = [];

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_tasks':
      return data;
    case 'update_task': {
      const matchIndex = state.findIndex((x) => x.id === data.id);
      const tasks = [...state];
      const match = tasks[matchIndex];

      if (match.attachments?.length && !data.attachments?.length) {
        data.attachments = match.attachments;
      }

      tasks.splice(matchIndex, 1, {
        ...tasks[matchIndex],
        ...data,
      });
      return tasks;
    }
    case 'remove_task': {
      const matchIndex = state.findIndex((x) => x.id === data.id);
      const tasks = [...state];
      tasks.splice(matchIndex, 1);
      return tasks;
    }
    case 'delete_attachment': {
      const taskIndex = state.findIndex((x) => x.id === data.taskId);
      const tasks = [...state];
      const task = tasks[taskIndex];
      const indexToBeRemoved = task.attachments.findIndex(
        (x) => x.id === data.attachmentId
      );
      const attachments = [...task.attachments];
      attachments.splice(indexToBeRemoved, 1);
      task.attachments = attachments;
      task.borrowerStatus =
        task.attachments.length > 0 ? 'Completed' : 'Not Completed';
      tasks[taskIndex] = task;
      return tasks;
    }
    default:
      return state;
  }
};

export default reducer;
