import { workstate } from 'src/actions';
import { apiAxios } from 'src/util';
import { LoanApplicationStatus } from 'src/types/BorrowerLoanApplication';

export const fetchBorrowerLoanApplications = () => (dispatch) => {
  return workstate(dispatch, 'fetch-all-loans', async () => {
    const res = await apiAxios.get('loans');
    dispatch({
      type: 'set_borrower_loan_applications',
      data: res.data,
    });
    return res.data;
  });
};

export const createLoan =
  ({ loanOfficerReferralToken }) =>
    (dispatch) => {
      return workstate(dispatch, 'create-loan', async () => {
        const res = await apiAxios.post('loans', {
          loanOfficerReferralToken,
        });
        dispatch({
          type: 'add_borrower_loan_application',
          data: res.data,
        });
        return res.data;
      });
    };

export const setLoanApplicationStatus = (loanGuid, status) => ({
  type: 'update_borrower_loan_application',
  data: {
    loanGuid,
    status: status ?? LoanApplicationStatus.Submitted,
  },
});
