import { Dispatch } from 'redux';
import { workstate } from 'src/actions';
import {
  OtherIncome,
  OtherIncomeActionType,
} from 'src/components/Loan/Application/Sections/IncomeInfo/Inputs/OtherIncomes/store/types';
import { Guid } from 'src/types';
import { apiAxios, hasAnyChanges, mergeDeep } from 'src/util';

export const createOtherIncome =
  (loanId: Guid, applicationId: Guid, data?: OtherIncome) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'create-other-income', async () => {
      const res = await apiAxios.post(
        `loans/${loanId}/applications/${applicationId}/otherIncomeSources`,
        data,
      );

      dispatch({
        type: OtherIncomeActionType.ADD,
        data: res.data,
      });
    });
  };

export const updateOtherIncome =
  (
    loanId: Guid,
    applicationId: Guid,
    otherIncomeId: string,
    data: OtherIncome,
  ) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'update-other-income', async () => {
      if (!hasAnyChanges(data)) return;
      const res = await apiAxios.patch(
        `loans/${loanId}/applications/${applicationId}/otherIncomeSources/${otherIncomeId}`,
        data,
      );

      dispatch({
        type: OtherIncomeActionType.UPDATE,
        data: mergeDeep(data, res.data),
      });
    });
  };

export const deleteOtherIncome =
  (loanId: Guid, applicationId: Guid, otherIncomeId: string) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'delete-other-income', async () => {
      await apiAxios.delete(
        `loans/${loanId}/applications/${applicationId}/otherIncomeSources/${otherIncomeId}`,
      );

      dispatch({
        type: OtherIncomeActionType.DELETE,
        data: otherIncomeId,
      });
    });
  };
