import React from 'react';
import { useHistory } from 'react-router-dom';
import FMLogoAnimated from 'src/assets/img/FMLogoAnimated.svg';
import './index.scss';

export default function FMLoader ({ loadingMessage, noTimer }) {
  const [redirect, setRedirect] = React.useState(false);

  const history = useHistory();

  React.useEffect(() => {
    let redirectTimer;
    if (noTimer) return;
    const msgTimer = setTimeout(() => {
      setRedirect(true);
      if (process.env.NODE_ENV !== 'development') {
        redirectTimer = setTimeout(() => {
          history.push('/');
        }, 3000);
      }
    }, 7000);
    return () => {
      // clear timer if already redirected
      clearTimeout(msgTimer);
      clearTimeout(redirectTimer);
    };
  }, [history, noTimer]);

  const redirectMessage = () => {
    return (
      <>
        Issue loading page, redirecting you to the homepage... {'\n'}
        <a href='/'>Click here if the page does not automatically redirect</a>
      </>
    );
  };

  return (
    <div className='fm-loader flex flex-container flex-center'>
      <img src={FMLogoAnimated} alt='Animated FM Logo' />
      {loadingMessage && <div className='loader-message'>{loadingMessage}</div>}
      {redirect && (
        <div className='loader-message pre-wrap'>{redirectMessage()}</div>
      )}
    </div>
  );
}
