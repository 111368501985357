/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { createAuthStore } from './Auth';
import env from 'src/env';
import LogRocket from 'logrocket';

const initialState = {};

const middleware = [
  thunk,
  LogRocket.reduxMiddleware({
    stateSanitizer: function (state) {
      let { applications, fields } = state;
      fields = Object.fromEntries(
        Object.entries(fields).filter(
          ([key]) => !key.toLowerCase().includes('ssn')
        )
      );

      applications = applications?.map((app) => {
        return {
          ...app,
          borrower: {
            ...app.borrower,
            taxIdentificationIdentifier: app.borrower
              ?.taxIdentificationIdentifier
              ? 'removed'
              : undefined,
          },
          coBorrower: {
            ...app.coBorrower,
            taxIdentificationIdentifier: app.coBorrower
              ?.taxIdentificationIdentifier
              ? 'removed'
              : undefined,
          },
        };
      });

      return {
        ...state,
        applications,
        fields,
      };
    },
  }),
];

export const store = createStore(
  rootReducer(),
  initialState,
  compose(
    applyMiddleware(...middleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ && env.DEPLOY_ENV !== 'production'
      ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

export const authStore = createAuthStore(store);
