import * as y from 'yup';
import { BorrowerType } from 'src/types';

enum OtherAssetType {
  Annuity = 'Annuity',
  Automobile = 'Automobile',
  Boat = 'Boat',
  BorrowerPrimaryHome = 'BorrowerPrimaryHome',
  BridgeLoanNotDeposited = 'BridgeLoanNotDeposited',
  CashOnHand = 'CashOnHand',
  EarnestMoney = 'EarnestMoney',
  EmployerAssistedHousing = 'EmployerAssistedHousing',
  LeasePurchaseFund = 'LeasePurchaseFund',
  NetWorthOfBusinessOwned = 'NetWorthOfBusinessOwned',
  PendingNetSaleProceedsFromRealEstateAssets = 'PendingNetSaleProceedsFromRealEstateAssets',
  ProceedsFromSaleOfNonRealEstateAsset = 'ProceedsFromSaleOfNonRealEstateAsset',
  ProceedsFromSecuredLoan = 'ProceedsFromSecuredLoan',
  ProceedsFromUnsecuredLoan = 'ProceedsFromUnsecuredLoan',
  LeasePurchaseCredit = 'LeasePurchaseCredit',
  LotEquity = 'LotEquity',
  Other = 'Other',
  RecreationalVehicle = 'RecreationalVehicle',
  RelocationFunds = 'RelocationFunds',
  SavingsBond = 'SavingsBond',
  SeverancePackage = 'SeverancePackage',
  SweatEquity = 'SweatEquity',
  TradeEquityFromPropertySwap = 'TradeEquityFromPropertySwap',
}

export const OtherAssetContract = y
  .object({
    id: y.string().uuid(),
    // entity with holder
    holderAddressCity: y.string().optional(),
    holderAddressPostalCode: y.string().optional(),
    holderAddressState: y.string().optional(),
    holderAddressStreetLine1: y.string().optional(),
    holderComments: y.string().optional(),
    holderEmail: y.string().optional(),
    holderFax: y.string().optional(),
    holderName: y.string().optional(),
    holderPhone: y.string().optional(),
    attention: y.string().optional(),
    // entity with title
    title: y.string().optional(),
    titlePhone: y.string().optional(),
    titleFax: y.string().optional(),
    // vod-specific
    borrowerType: y
      .mixed<BorrowerType>()
      .oneOf(Object.values(BorrowerType))
      .required(),
    assetType: y
      .mixed<OtherAssetType>()
      .oneOf(Object.values(OtherAssetType))
      .required(),
    cashOrMarketValue: y.number().positive(),
    otherDescription: y.string().optional(),
    printUserNameIndicator: y.boolean().optional(),
    printAttachmentIndicator: y.boolean().optional(),
    otherAssetDate: y.string().optional(),
    printUserJobTitleIndicator: y.boolean().optional(),
  })
  .strict();

export interface OtherAsset extends y.Asserts<typeof OtherAssetContract> {}

export enum OtherAssetActionType {
  SET = 'set_otherasset',
  ADD = 'add_otherasset',
  UPDATE = 'update_otherasset',
  DELETE = 'delete_otherasset',
}

export const otherAssetNewValidationErrorMap = {
  _entityName: 'OtherAsset',
  'assets-liabilities': {
    'other-assets': {
      currentApplication: {
        otherAssets: {
          '{0}': {
            assetType: 'Please enter the other asset type',
            cashOrMarketValue: 'Please enter the other asset estimated value',
          },
        },
      },
    },
  },
};

export const coborrowerOtherAssetNewValidationErrorMap = {
  _entityName: 'OtherAsset',
  'assets-liabilities': {
    'coborrower-other-assets': {
      currentApplication: {
        otherAssets: {
          '{0}': {
            assetType: "Please enter your coborrower's other asset type",
            cashOrMarketValue:
              "Please enter your coborrower's other asset estimated value",
          },
        },
      },
    },
  },
};
