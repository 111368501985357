import { v4 as uuid } from 'uuid';
export * from './borrower';
export * from './invitation';
export * from './consent';
export * from './loanOfficer';
export * from './loanOfficerNotification';
export * from './loan';
export * from './postapp';
export * from './tasks';
export * from './efolder';
export * from './logicApps';
export * from './messages';
export * from './credit';
export * from 'src/components/Dashboard/store/actions';
export * from 'src/components/Loan/Application/store/applications/actions';
export * from 'src/components/Loan/Application/store/loan/actions';
export * from 'src/components/Loan/Application/store/fields/actions';
export * from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Assets/store/actions';
export * from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/OtherAssets/store/actions';
export * from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/GiftsGrants/store/actions';
export * from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Liabilities/store/actions';
export * from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/OtherLiabilities/store/actions';
export * from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Properties/store/actions';
export * from 'src/components/Loan/Application/Sections/BorrowerInfo/Inputs/AlternateNames/store/actions';
export * from 'src/components/Loan/Application/Sections/BorrowerInfo/Inputs/Residence/store/actions';
export * from 'src/components/Loan/Application/Sections/IncomeInfo/Inputs/OtherIncomes/store/actions';
export * from 'src/components/Loan/Application/Sections/EmploymentInfo/Inputs/Employments/store/actions';

export const setError = (str) => ({
  type: 'set_error',
  data: str,
});

export const setWarning = (str) => ({
  type: 'set_warning',
  data: str,
});

export const setLastVisitedLoanGuid = (guid) => ({
  type: 'set_last_loan_guid',
  data: guid,
});

/**
 * Perform some async operation and add it to the app.working store.
 * Will add any axios request with a 4** or 5** `status` other than a 404 to app.error.
 * Any other axios exceptions will be added to app.warning
 * @param {import('redux').Dispatch} dispatch
 * @param {string} section
 * @param {Function<any>} fn
 * @param {{ handleError: boolean }} options
 * @returns {any | Error} The result of executing `fn`, or an Error instance
 */
export const workstate = async (dispatch, section, fn, options = {}) => {
  const opts = {
    handleError: true,
    ...options,
  };
  let actionName = 'loading';
  if (fn === undefined) {
    fn = section;
  } else {
    actionName = section;
  }
  const uid = uuid();
  dispatch({
    type: 'set_working',
    data: {
      action: 'start',
      uuid: uid,
      name: actionName,
    },
  });
  dispatch(setError(''));
  dispatch(setWarning(''));
  try {
    return await fn();
  } catch (e) {
    if (process.env.NODE_ENV !== 'test') console.warn(e);
    if (opts.handleError) {
      let msg = e.response?.data?.messages?.[0] ?? e.message.trim();
      if (msg === 'Network Error') {
        msg = 'Could not connect to Cinch Borrower Service';
      } else if (msg === 'access token has expired') {
        msg = 'Access token has expired. Please refresh the page.';
      }
      const status = e.response?.status ?? e.status;
      if (status) {
        if (['0', '4', '5'].includes(status.toString()[0]) && status !== 404) {
          // quick hack since api isnt returning 409 for locked here
          if (e.response?.data?.messages?.[0]?.includes('locked')) {
            dispatch(
              setError(
                'Your loan is currently locked and changes cannot be saved. Please try again later.'
              )
            );
          } else {
            dispatch(setError(msg));
          }
        } else {
          if (status !== 404) {
            dispatch(setWarning(msg));
          }
        }
        return e;
      } else {
        dispatch(setWarning(msg));
        return new Error(msg);
      }
    } else {
      throw e;
    }
  } finally {
    dispatch({
      type: 'set_working',
      data: {
        action: 'stop',
        uuid: uid,
      },
    });
  }
};
