import React, { useContext } from 'react';
import { AuthContext } from './index';

export default function (mapFn) {
  return function (ReactComponent) {
    return function AuthConnectFunction (componentProps) {
      return (
        <AuthContext.Consumer>
          {({ store }) => {
            const { dispatch, getState } = store;
            const props =
              mapFn && store ? mapFn(getState(), componentProps) : {};
            return (
              <ReactComponent
                {...componentProps}
                {...props}
                authdispatch={dispatch}
              />
            );
          }}
        </AuthContext.Consumer>
      );
    };
  };
}

export const useAuth = () => {
  const { store } = useContext(AuthContext);
  return store.getState();
};

export const useAuthSession = () => {
  const auth = useAuth();
  return auth?.session;
};

/**
 * @returns {boolean} Whether the current user is a loan officer
 */
export const useIsLoanOfficer = () => {
  const session = useAuthSession();
  return session?.isLoanOfficer ?? false;
};

export const useIsAuthenticated = () => {
  const auth = useAuth();
  return auth?.isAuthenticated();
};
