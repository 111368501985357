import { apiAxios, mergeDeep } from 'src/util';
import { workstate } from '.';

export const getUnreadMessagesForLoan = (loanGuid) => (dispatch) => {
  return workstate(dispatch, 'fetch-loan-messages', async () => {
    const res = await apiAxios.get(`loans/${loanGuid}/messages/unread`);

    dispatch({
      type: 'set_messages',
      data: res.data,
    });
  });
};

export const getMessagesForTask = (taskId) => (dispatch) => {
  return workstate(dispatch, 'fetch-messages', async () => {
    const res = await apiAxios.get(`tasks/${taskId}/messages`);

    dispatch({
      type: 'set_messages',
      data: res.data,
    });
  });
};

export const updateMessage = (messageId, data) => (dispatch) => {
  return workstate(dispatch, 'update-message', async () => {
    const res = await apiAxios.patch(`messages/${messageId}`, data);
    dispatch({
      type: 'update_message',
      data: mergeDeep(data, res.data),
    });
  });
};

export const sendTaskMessage = (taskId, data) => (dispatch) => {
  return workstate(dispatch, 'send-message', async () => {
    const res = await apiAxios.post(`tasks/${taskId}/messages`, data);
    dispatch({
      type: 'add_message',
      data: res.data,
    });

    // load any new messages that may have been sent
    if (res.data.loanGuid) {
      await dispatch(getUnreadMessagesForLoan(res.data.loanGuid));
    }
  });
};

export const deleteMessage = (messageId) => (dispatch) => {
  return workstate(dispatch, 'delete-message', async () => {
    try {
      await apiAxios.delete(`messages/${messageId}`);
      dispatch({
        type: 'remove_message',
        data: messageId,
      });
    } catch (e) {
      return e;
    }
  });
};

export const updateMessageReadStatus = (messageIds, status) => (dispatch) => {
  return workstate(dispatch, 'update-message', async () => {
    const data = {
      ids: messageIds,
      isRead: status,
    };
    const res = await apiAxios.patch('messages/read', data);
    // wait a little bit so user has a chance to see the unread indicator
    setTimeout(() => {
      dispatch({
        type: 'set_messages',
        data: res.data.ids.map((id) => ({
          id,
          isRead: res.data.isRead,
        })),
      });
    }, 4000);
  });
};
