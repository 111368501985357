import * as y from 'yup';
import { BorrowerOrCoBorrowerType } from 'src/types';
import { isEmpty } from 'src/util';

export enum OwnershipInterestType {
  LessThan25Percent = 'LessThan25Percent',
  GreaterThanOrEqualTo25Percent = 'GreaterThanOrEqualTo25Percent',
}

export const EmploymentContract = y.object({
  id: y.string().uuid(),
  // entity with holder
  holderAddressCity: y.string().optional(),
  holderAddressPostalCode: y.string().optional(),
  holderAddressState: y.string().optional(),
  holderAddressStreetLine1: y.string().optional(),
  holderComments: y.string().optional(),
  holderEmail: y.string().optional(),
  holderFax: y.string().optional(),
  holderName: y.string().optional(),
  holderPhone: y.string().optional(),
  attention: y.string().optional(),
  // entity with title
  title: y.string().optional(),
  titlePhone: y.string().optional(),
  titleFax: y.string().optional(),
  // entity
  selfEmployedIndicator: y.boolean(),
  employerComments: y.string().optional(),
  businessOwnedPercent: y
    .string()
    .when(['selfEmployedIndicator', 'employerComments'], {
      is: (selfEmployedIndicator, employerComments) =>
        selfEmployedIndicator === true &&
        employerComments?.includes('NoIndependentContractor'),
      then: y
        .string()
        .required('Please enter the percent of the business that you own'),
      otherwise: y.string().optional().notRequired(),
    }),
  employerName: y.string().required(),
  currentEmploymentIndicator: y.boolean(),
  startDate: y.string().required(),
  employmentStartDate: y.string().optional(),
  endDate: y.string().optional().when('currentEmploymentIndicator', {
    is: false,
    then: y.string().required(),
  }),
  positionDescription: y
    .string()
    .test(
      'key:positionDescription',
      'Please enter your position',
      (str, ctx) => {
        return !isEmpty(str?.split(' / ')[0]?.trim());
      },
    )
    .test(
      'key:employmentIndustry',
      'Please select your industry',
      (str, ctx) => {
        return !isEmpty(str?.split(' / ')[1]?.trim());
      },
    ),
  timeInLineOfWorkYears: y.number(),
  phoneNumber: y
    .string()
    .optional()
    .when(['selfEmployedIndicator', 'employerComments'], {
      is: (selfEmployedIndicator, employerComments) =>
        !selfEmployedIndicator ||
        (selfEmployedIndicator === true &&
          !employerComments?.includes('YesIndependentContractor')),
      then: y.string().required(),
    }),
  addressStreetLine1: y
    .string()
    .optional()
    .when('employerComments', {
      is: (val) => !val?.includes('YesIndependentContractor'),
      then: y.string().required(),
    }),
  addressCity: y
    .string()
    .optional()
    .when('employerComments', {
      is: (val) => !val?.includes('YesIndependentContractor'),
      then: y.string().required(),
    }),
  addressState: y
    .string()
    .optional()
    .when('employerComments', {
      is: (val) => !val?.includes('YesIndependentContractor'),
      then: y.string().required(),
    }),
  addressPostalCode: y
    .string()
    .optional()
    .when('employerComments', {
      is: (val) => !val?.includes('YesIndependentContractor'),
      then: y.string().required(),
    }),
  country: y.string().optional(),
  ownershipInterestType: y
    .mixed()
    .oneOf(Object.values(OwnershipInterestType))
    .optional(),
  specialEmployerRelationshipIndicator: y.boolean(),
  basePayAmount: y
    .number()
    .optional()
    .when('employerComments', {
      is: (val) => val?.includes('Hourly'),
      then: y.number().required('Please provide your hourly income'),
    })
    .when('employerComments', {
      is: (val) => val?.includes('Salary'),
      then: y.number().required('Please provide your salary'),
    }),
  commissionsAmount: y.number().optional(),
  bonusAmount: y.number().optional(),
  otherAmount: y
    .number()
    .optional()
    .when('employerComments', {
      is: (val) =>
        val?.includes('OtherPaymentType') ||
        val?.includes('YesIndependentContractor'),
      then: y.number().required('Please enter your total annual income'),
    }),
  overtimeAmount: y.number().optional(),
  employmentMonthlyIncomeAmount: y.number().optional(),
  monthlyIncomeAmount: y.number().optional(),
  militaryEntitlement: y.number().optional(),
  militaryCombatPay: y.number().optional(),
  militaryFlightPay: y.number().optional(),
  militaryHazardPay: y.number().optional(),
  militaryOverseasPay: y.number().optional(),
  militaryPropPay: y.number().optional(),
  clothingAllowance: y.number().optional(),
  rationsAllowance: y.number().optional(),
  variableHousingAllowance: y.number().optional(),
  quartersAllowance: y.number().optional(),
  altId: y.string().optional(),
});

export const StoreEmploymentContract = EmploymentContract.shape({
  owner: y
    .mixed<BorrowerOrCoBorrowerType>()
    .oneOf(Object.values(BorrowerOrCoBorrowerType)),
});
/**
 * An Employment (Employment) entity describes an Employment for a Borrower
 */
export interface Employment extends y.Asserts<typeof EmploymentContract> {}
export interface StoreEmployment
  extends y.Asserts<typeof StoreEmploymentContract> {}

export enum EmploymentActionType {
  SET = 'set_employments',
  ADD = 'add_employment',
  UPDATE = 'update_employment',
  DELETE = 'delete_employment',
}

export const employmentNewValidationErrorMap = {
  _entityName: 'Employment',
  employment: {
    history: {
      currentApplication: {
        borrower: {
          employment: {
            min: 'Please provide at least one employment',
            '{0}': {
              selfEmployedIndicator:
                'Please select whether you are self employed',
              businessOwnedPercent:
                'Please enter the percent of business owned',
              employerComments:
                'Please select payment type and whether you are an independent contractor',
              specialEmployerRelationshipIndicator:
                'Please select if you employed by a family member, property seller, real estate agent, or other party to the transaction',
              employerName: "Please enter the employer's name",
              startDate: 'Please select your start date',
              endDate: 'Please select your end date',
              positionDescription: 'Please enter your position description',
              employmentIndustry: 'Please select your industry',
              phoneNumber: "Please enter the employer's phone number",
              addressStreetLine1: "Please enter the employer's street address",
              addressCity: "Please enter the employer's city",
              addressState: "Please enter the employer's state",
              addressPostalCode: "Please enter the employer's postal code",
              currentEmploymentIndicator:
                'Please select if you currently work here',
              timeInLineOfWorkYears:
                'Please enter how long you have worked here',
            },
          },
        },
      },
    },
  },
  income: {
    information: {
      currentApplication: {
        borrower: {
          employment: {
            '{0}': {
              basePayAmount: 'Please enter your base pay',
              otherAmount: 'Please type in your total annual income',
            },
          },
        },
      },
    },
  },
};

export const coborrowerEmploymentNewValidationErrorMap = {
  _entityName: 'Employment',
  employment: {
    'coborrower-history': {
      currentApplication: {
        coBorrower: {
          employment: {
            min: 'Please provide at least one employment for your coborrower',
            '{0}': {
              selfEmployedIndicator:
                'Please select whether your coborrower is self employed',
              businessOwnedPercent:
                'Please enter the percent of business owned',
              employerComments:
                'Please select payment type and whether your coborrower is an independent contractor',
              specialEmployerRelationshipIndicator:
                'Please select if your coborrower is employed by a family member, property seller, real estate agent, or other party to the transaction',
              employerName: "Please enter the employer's name",
              startDate: "Please select your coborrower's start date",
              endDate: "Please select your coborrower's end date",
              positionDescription:
                "Please enter your coborrower's position description",
              employmentIndustry: "Please select your coborrower's industry",
              phoneNumber: "Please enter the employer's phone number",
              addressStreetLine1: "Please enter the employer's street address",
              addressCity: "Please enter the employer's city",
              addressState: "Please enter the employer's state",
              addressPostalCode: "Please enter the employer's postal code",
              currentEmploymentIndicator:
                'Please select if your coborrower currently works here',
              timeInLineOfWorkYears:
                'Please enter how long your coborrower has worked here',
            },
          },
        },
      },
    },
  },
  income: {
    'coborrower-information': {
      currentApplication: {
        coBorrower: {
          employment: {
            '{0}': {
              basePayAmount: "Please enter your coborrower's base pay",
              otherAmount:
                "Please type in your coborrower's total annual income",
            },
          },
        },
      },
    },
  },
};
