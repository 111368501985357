import * as y from 'yup';
import { BorrowerType, EntityReferenceContract } from 'src/types';

export enum LiabilityType {
  ChildCare = 'ChildCare',
  ChildSupport = 'ChildSupport',
  CollectionsJudgementsAndLiens = 'CollectionsJudgementsAndLiens',
  HELOC = 'HELOC',
  Installment = 'Installment',
  LeasePayments = 'LeasePayments',
  MortgageLoan = 'MortgageLoan',
  Open30DayChargeAccount = 'Open30DayChargeAccount',
  OtherLiability = 'OtherLiability',
  Revolving = 'Revolving',
  SeparateMaintenanceExpense = 'SeparateMaintenanceExpense',
  OtherExpense = 'OtherExpense',
  Taxes = 'Taxes',
  TaxLien = 'TaxLien',
}

enum UCDPayoffType {
  BorrowerEstimatedTotalMonthlyLiabilityPayment = 'BorrowerEstimatedTotalMonthlyLiabilityPayment',
  CollectionsJudgmentsAndLiens = 'CollectionsJudgmentsAndLiens',
  DeferredStudentLoan = 'DeferredStudentLoan',
  DelinquentTaxes = 'DelinquentTaxes',
  FirstPositionMortgageLien = 'FirstPositionMortgageLien',
  Garnishments = 'Garnishments',
  HELOC = 'HELOC',
  HomeownersAssociationLien = 'HomeownersAssociationLien',
  Installment = 'Installment',
  LeasePayment = 'LeasePayment',
  MortgageLoan = 'MortgageLoan',
  Open30DayChargeAccount = 'Open30DayChargeAccount',
  Other = 'Other',
  PersonalLoan = 'PersonalLoan',
  Revolving = 'Revolving',
  SecondPositionMortgageLien = 'SecondPositionMortgageLien',
  Taxes = 'Taxes',
  TaxLien = 'TaxLien',
  ThirdPositionMortgageLien = 'ThirdPositionMortgageLien',
  UnsecuredHomeImprovementLoanInstallment = 'UnsecuredHomeImprovementLoanInstallment',
  UnsecuredHomeImprovementLoanRevolving = 'UnsecuredHomeImprovementLoanRevolving',
}

export const VolContract = y.object({
  id: y.string().uuid(),
  // entity with holder
  holderAddressCity: y.string().optional(),
  holderAddressPostalCode: y.string().optional(),
  holderAddressState: y.string().optional(),
  holderAddressStreetLine1: y.string().optional(),
  holderComments: y.string().optional(),
  holderEmail: y.string().optional(),
  holderFax: y.string().optional(),
  holderName: y
    .string()
    .optional()
    .when('liabilityType', {
      is: (val) => val?.includes('Mortgage'),
      then: y.string().required(),
    }),
  holderPhone: y.string().optional(),
  attention: y.string().optional(),
  // entity with title
  title: y.string().optional(),
  titlePhone: y.string().optional(),
  titleFax: y.string().optional(),
  // vol-specific
  liabilityType: y
    .mixed<LiabilityType>()
    .oneOf(Object.values(LiabilityType))
    .required(),
  monthlyPaymentAmount: y.number().required(),
  descriptionOfPurpose: y
    .string()
    .optional()
    .when('liabilityType', {
      is: (val) => val?.includes('Other'),
      then: y.string().required(),
    }),
  remainingTermMonths: y.number().required(),
  accountIdentifier: y.string().optional(),
  date: y.string().optional(),
  exclusionIndicator: y.boolean().optional(),
  monthsToExclude: y.number().optional(),
  nameInAccount: y.string().optional(),
  noLinkToDocTrackIndicator: y.boolean().optional(),
  owner: y.mixed<BorrowerType>().oneOf(Object.values(BorrowerType)).optional(),
  payoffStatusIndicator: y.boolean().optional(),
  prepaymentPenaltyAmount: y.number().optional(),
  printAttachmentIndicator: y.boolean().optional(),
  includeTaxesAndInsuranceIndicator: y.boolean().optional(),
  reoProperty: EntityReferenceContract.optional().nullable(),
  subjectLoanResubordinationIndicator: y.boolean().optional(),
  toBePaidOffAmount: y.number().optional(),
  unpaidBalanceAmount: y.number().optional(),
  isDebtNotSecuredToSubjectPropertyIndicator: y.boolean().optional(),
  ucdPayoffType: y
    .mixed<UCDPayoffType>()
    .oneOf(Object.values(UCDPayoffType))
    .optional(),
  payoffIncludedIndicator: y.boolean().optional(),
  printUserNameIndicator: y.boolean().optional(),
  printUserJobTitleIndicator: y.boolean().optional(),
  lates12Month30Day: y.number().optional(),
  lates24Month30Day: y.number().optional(),
  lates25Month30Day: y.number().optional(),
  lates12Month60Day: y.number().optional(),
  lates24Month60Day: y.number().optional(),
  lates25Month60Day: y.number().optional(),
  lates12Month90Day: y.number().optional(),
  lates24Month90Day: y.number().optional(),
  lates25Month90Day: y.number().optional(),
  lates12Month120Day: y.number().optional(),
  lates24Month120Day: y.number().optional(),
  lates25Month120Day: y.number().optional(),
  lates12Month150Day: y.number().optional(),
  lates24Month150Day: y.number().optional(),
  lates25Month150Day: y.number().optional(),
  subjectPropertyIndicator: y.boolean().optional(),
  currentLienPosition: y.string().optional(),
  proposedLienPosition: y.string().optional(),
  bankLiabilityConsideredIndicator: y.boolean().optional(),
  creditLimit: y.number().optional(),
  mortgageType: y.string().optional(),
  otherDescription: y.string().optional(),
  foreignAddressIndicator: y.boolean().optional(),
  country: y.string().optional(),
});

export interface Liability extends y.Asserts<typeof VolContract> {}

export enum LiabilityActionType {
  SET = 'set_liability',
  ADD = 'add_liability',
  UPDATE = 'update_liability',
  DELETE = 'delete_liability',
}

export const liabilityNewValidationErrorMap = {
  _entityName: 'Liability',
  'assets-liabilities': {
    payments: {
      currentApplication: {
        vols: {
          '{0}': {
            liabilityType: 'Please select the payment type',
            monthlyPaymentAmount: 'Please enter the payment amount',
            remainingTermMonths:
              'Please enter number of months left on your payment',
            descriptionOfPurpose: 'Please enter payment description',
            holderName: 'Please enter mortgage holder name',
          },
        },
      },
    },
  },
};

export const coborrowerLiabilityNewValidationErrorMap = {
  _entityName: 'Liability',
  'assets-liabilities': {
    'coborrower-payments': {
      currentApplication: {
        vols: {
          '{0}': {
            liabilityType: "Please select your coborrower's payment type",
            monthlyPaymentAmount:
              "Please enter your coborrower's payment amount",
            remainingTermMonths:
              "Please enter number of months left on your coborrower's payment",
            descriptionOfPurpose:
              "Please enter coborrower's payment description",
            holderName: 'Please enter mortgage holder name',
          },
        },
      },
    },
  },
};
