import { Dispatch } from 'redux';
import { workstate } from 'src/actions';
import { Guid } from 'src/types';
import { apiAxios } from 'src/util';

export type FieldDictionary = {
  [key: string]: string | number | boolean | null;
};
export enum FieldActionType {
  /**
   * Overwrite all existing fields in the store
   */
  SET = 'set_fields',
  /**
   * Insert any new fields, update existing ones
   */
  UPDATE = 'update_fields',
}

export const updateCustomFields =
  (loanGuid: Guid, data: FieldDictionary) => (dispatch: Dispatch) => {
    return workstate(dispatch, 'update-custom-fields', async () => {
      // fields can just be updated in store immediately
      dispatch({
        type: FieldActionType.UPDATE,
        data: data,
      });
      await apiAxios.patch<FieldDictionary>(
        `loans/${loanGuid}/customFields`,
        data,
      );
      /* dispatch({
      type: FieldActionType.UPDATE,
      data: res.data,
    }); */
    });
  };
