import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * @param {string} formId
 * @param {boolean} isDirty
 */
export const useFormIsDirtyTracking = (formId, formState) => {
  const dispatch = useDispatch();
  // console.log(formId, 'is', formState.isDirty, formState.dirtyFields);
  useEffect(() => {
    dispatch({
      type: 'set_form_isdirty',
      data: {
        formId,
        isDirty: formState.isDirty,
      },
    });
  }, [dispatch, formId, formState]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'set_form_isdirty',
        data: {
          formId,
          isDirty: false,
        },
      });
    };
  }, [dispatch, formId]);
};

export const useHasDirtyForm = () => {
  // return false;
  const forms = useSelector(
    (store) => store.dirtyApplicationForms,
    (left, right) => JSON.stringify(left) === JSON.stringify(right)
  );
  return useMemo(() => Object.values(forms).some((x) => x), [forms]);
};

export const useIsSavingLoan = () => {
  return (
    useSelector((store) => store.app.working).filter((x) =>
      x.name?.startsWith('update')
    ).length > 0
  );
};
