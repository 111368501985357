import * as y from 'yup';
import { BorrowerOrCoBorrowerType } from 'src/types';
import { EmploymentContract } from 'src/components/Loan/Application/Sections/EmploymentInfo/Inputs/Employments/store/types';
import { URLAAlternateNameContract } from 'src/components/Loan/Application/Sections/BorrowerInfo/Inputs/AlternateNames/store/types';
import { ResidenceContract } from 'src/components/Loan/Application/Sections/BorrowerInfo/Inputs/Residence/store/types';
import { fieldIds } from 'src/util';
import { ValidationLoan } from 'src/types/loan';

export enum PriorPropertyUsageType {
  PrimaryResidence = 'PrimaryResidence',
  FHASecondaryResidence = 'FHASecondaryResidence',
  SecondaryResidence = 'SecondaryResidence',
  Investment = 'Investment',
}

export enum PriorPropertyTitleType {
  Sole = 'Sole',
  JointWithSpouse = 'JointWithSpouse',
  JointWithOtherThanSpouse = 'JointWithOtherThanSpouse',
}

export enum MaritalStatusType {
  Married = 'Married',
  Unmarried = 'Unmarried',
  Separated = 'Separated',
}

export enum ResidencyStatusType {
  USCitizen = 'USCitizen',
  PermanentResidentAlien = 'PermanentResidentAlien',
  NonPermanentResidentAlien = 'NonPermanentResidentAlien',
}

export const BorrowerContract = y.object({
  id: y.string().uuid(),
  applicantType: y
    .mixed<BorrowerOrCoBorrowerType>()
    .oneOf(Object.values(BorrowerOrCoBorrowerType)),
  firstName: y.string().required(),
  middleName: y.string().optional(),
  lastName: y.string().required(),
  suffix: y.string().optional(),
  emailAddressText: y.string().required(),
  homePhoneNumber: y.string().required(),
  homeownerPastThreeYearsIndicator: y.boolean(),
  priorPropertyUsageType: y
    .mixed<PriorPropertyUsageType>()
    .oneOf(Object.values(PriorPropertyUsageType))
    .optional()
    .when('homeownerPastThreeYearsIndicator', (val, schema) => {
      if (val) return schema.required();
      return schema;
    }),
  priorPropertyTitleType: y
    .mixed<PriorPropertyTitleType>()
    .oneOf(Object.values(PriorPropertyTitleType))
    .optional()
    .when('homeownerPastThreeYearsIndicator', (val, schema) => {
      if (val) return schema.required();
      return schema;
    }),
  birthDate: y.date().required().max(new Date()),
  maritalStatusType: y
    .mixed<MaritalStatusType>()
    .oneOf(Object.values(MaritalStatusType))
    .required(),
  schoolingTermYears: y.number().positive().required(),
  dependentsAgesDescription: y.string().optional(),
  urlaAlternateNames: y.array(URLAAlternateNameContract),
  residences: y.array(ResidenceContract),
  employment: y.array(EmploymentContract).when(
    ['applicantType', '$loan'],
    // @ts-ignore
    (applicantType: BorrowerOrCoBorrowerType, loan: ValidationLoan, schema) => {
      if (
        (applicantType === BorrowerOrCoBorrowerType.Borrower &&
          loan?.customFields?.[fieldIds.hasEmployment] === 'true') ||
        (applicantType === BorrowerOrCoBorrowerType.CoBorrower &&
          loan?.customFields?.[fieldIds.hasCoborrowerEmployment] === 'true')
      ) {
        return schema.min(1, 'Please provide at least 1 employment');
      }
      return schema;
    },
  ),
  urla2020CitizenshipResidencyType: y
    .mixed<ResidencyStatusType>()
    .oneOf(Object.values(ResidencyStatusType)),
  intentToOccupyIndicator: y.boolean().optional(),
  mailingAddressSameAsPresentIndicator: y.boolean().optional(),
  mailingAddress: y
    .object({
      addressCity: y.string().optional(),
      addressPostalCode: y.string().optional(),
      addressState: y.string().optional(),
      addressStreetLine1: y.string().optional(),
      urla2020StreetAddress: y.string().optional(),
      country: y.string().optional(),
    })
    .optional()
    .when(
      ['applicantType', '$loan'],
      // @ts-ignore
      (
        applicantType: BorrowerOrCoBorrowerType,
        loan: ValidationLoan,
        schema,
      ) => {
        if (
          (applicantType === BorrowerOrCoBorrowerType.Borrower &&
            !loan?.currentApplication?.borrower
              .mailingAddressSameAsPresentIndicator) ||
          (applicantType === BorrowerOrCoBorrowerType.CoBorrower &&
            loan?.customFields?.[fieldIds.hasCoborrower] === 'true' &&
            !(
              loan?.currentApplication?.coBorrower
                .mailingAddressSameAsPresentIndicator ||
              loan?.customFields?.[fieldIds.sameMailingAddressAsBorrower] ===
                'Y' ||
              (loan?.customFields?.[fieldIds.sameMailingAddressAsBorrower] ===
                undefined &&
                Object.entries(
                  loan?.currentApplication?.coBorrower.mailingAddress ?? {},
                ).length === 0)
            ))
        ) {
          return schema.shape({
            addressCity: y.string().required(),
            addressPostalCode: y.string().required(),
            addressState: y.string().required(),
            addressStreetLine1: y.string().required(),
            urla2020StreetAddress: y.string().required(),
            country: y.string().optional(),
          });
        }
        return schema;
      },
    ),
  // declarations
  // questions-1
  declarationsJIndicator: y.boolean().optional(),
  declarationsKIndicator: y.boolean().optional(),
  partyToLawsuitIndicatorUrla: y.boolean().optional(),
  sectionIExplanation: y.string().optional(),
  outstandingJudgementsIndicator: y.boolean().optional(),
  sectionGExplanation: y.string().optional(),
  borrowedDownPaymentIndicator: y.boolean().optional(),
  sectionCExplanation: y.string().optional(),
  undisclosedBorrowedFundsIndicator: y.boolean().optional(),
  undisclosedBorrowedFundsAmount: y.number().optional(),
  coMakerEndorserOfNoteIndicator: y.boolean().optional(),
  sectionFExplanation: y.string().optional(),
  undisclosedComakerOfNoteIndicator: y.boolean().optional(),
  specialBorrowerSellerRelationshipIndicator: y.boolean().optional(),
  undisclosedMortgageApplicationIndicator: y.boolean().optional(),
  undisclosedCreditApplicationIndicator: y.boolean().optional(),
  // questions-2
  alimonyChildSupportObligationIndicator: y.boolean().optional(),
  bankruptcyIndicator: y.boolean().optional(),
  bankruptcyIndicatorChapterSeven: y.boolean().optional(),
  bankruptcyIndicatorChapterEleven: y.boolean().optional(),
  bankruptcyIndicatorChapterTwelve: y.boolean().optional(),
  bankruptcyIndicatorChapterThirteen: y.boolean().optional(),
  priorPropertyShortSaleCompletedIndicator: y.boolean().optional(),
  sectionKExplanation: y.string().optional(),
  priorPropertyForeclosureCompletedIndicator: y.boolean().optional(),
  sectionLExplanation: y.string().optional(),
  priorPropertyDeedInLieuConveyedIndicator: y.boolean().optional(),
  sectionJExplanation: y.string().optional(),
  loanForeclosureOrJudgementIndicator: y.boolean().optional(),
  presentlyDelinquentIndicatorUrla: y.boolean().optional(),
  sectionHExplanation: y.string().optional(),
  propertyProposedCleanEnergyLienIndicator: y.boolean().optional(),
  // military
  selfDeclaredMilitaryServiceIndicator: y.boolean().optional(),
  militaryServiceExpectedCompletionDate: y.string().optional(),
  veteran: y.boolean().optional(),
  reserveNationalGuardReserveActivated: y.boolean().optional(),
  spousalVaBenefitsEligibilityIndicator: y.boolean().optional(),
  // ethnicity
  hmdaEthnicityHispanicLatinoIndicator: y.boolean().optional(),
  hmdaMexicanIndicator: y.boolean().optional(),
  hmdaPuertoRicanIndicator: y.boolean().optional(),
  hmdaCubanIndicator: y.boolean().optional(),
  hmdaHispanicLatinoOtherOriginIndicator: y.boolean().optional(),
  hmdaOtherHispanicLatinoOrigin: y
    .string()
    .optional()
    .when('hmdaHispanicLatinoOtherOriginIndicator', {
      is: true,
      then: y.string().required(),
    }),
  hmdaEthnicityNotHispanicLatinoIndicator: y.boolean().optional(),
  hmdaEthnicityDoNotWishIndicator: y.boolean().optional(),
  // race
  hmdaAmericanIndianIndicator: y.boolean().optional(),
  hmdaAmericanIndianTribe: y
    .string()
    .optional()
    .when('hmdaAmericanIndianIndicator', {
      is: true,
      then: y.string().required(),
    }),
  hmdaAsianIndicator: y.boolean().optional(),
  hmdaAsianIndianIndicator: y.boolean().optional(),
  hmdaChineseIndicator: y.boolean().optional(),
  hmdaFilipinoIndicator: y.boolean().optional(),
  hmdaJapaneseIndicator: y.boolean().optional(),
  hmdaKoreanIndicator: y.boolean().optional(),
  hmdaVietnameseIndicator: y.boolean().optional(),
  hmdaAsianOtherRaceIndicator: y.boolean().optional(),
  hmdaOtherAsianRace: y
    .string()
    .optional()
    .when('hmdaAsianOtherRaceIndicator', {
      is: true,
      then: y.string().required(),
    }),
  hmdaAfricanAmericanIndicator: y.boolean().optional(),
  hmdaPacificIslanderIndicator: y.boolean().optional(),
  hmdaNativeHawaiianIndicator: y.boolean().optional(),
  hmdaGuamanianOrChamorroIndicator: y.boolean().optional(),
  hmdaSamoanIndicator: y.boolean().optional(),
  hmdaPacificIslanderOtherIndicator: y.boolean().optional(),
  hmdaOtherPacificIslanderRace: y
    .string()
    .optional()
    .when('hmdaPacificIslanderOtherIndicator', {
      is: true,
      then: y.string().required(),
    }),
  hmdaWhiteIndicator: y.boolean().optional(),
  hmdaRaceDoNotWishProvideIndicator: y.boolean().optional(),
  // other
  languagePreference: y.string().optional(),
  languageCodeOtherDescription: y
    .string()
    .optional()
    .when('languagePreference', {
      is: 'OtherIndicator',
      then: y.string().required(),
    }),
  hmdaGendertypeMaleIndicator: y.boolean().optional(),
  hmdaGendertypeFemaleIndicator: y.boolean().optional(),
  hmdaGendertypeDoNotWishIndicator: y.boolean().optional(),
  taxIdentificationIdentifier: y
    .string()
    .matches(/^\d{3}-\d{2}-\d{4}$/, {
      excludeEmptyString: true,
      message: 'Please enter a valid SSN',
    })
    .required(),
  twoYearsMinimum: y.string().when(
    ['applicantType', '$loan'],
    // @ts-ignore
    (applicantType: BorrowerOrCoBorrowerType, loan: ValidationLoan, schema) => {
      const borrowerKey =
        applicantType === BorrowerOrCoBorrowerType.CoBorrower
          ? 'coBorrower'
          : 'borrower';
      const residences = loan?.currentApplication?.[borrowerKey]?.residences;

      if (
        applicantType === BorrowerOrCoBorrowerType.CoBorrower &&
        loan?.customFields?.[fieldIds.sameAddressAsBorrower] === 'Y'
      ) {
        return schema.optional();
      }

      if (!residences) return schema.required();
      const months = residences.reduce(
        (a, x) => a + (x.durationTermMonths || 0),
        0,
      );
      const years = residences.reduce(
        (a, x) => a + (x.durationTermYears || 0),
        0,
      );
      if (Math.floor(months / 12) + Number(years) >= 2) {
        return schema.optional();
      } else {
        return schema.required();
      }
    },
  ),
});

export const StoreBorrowerContract = BorrowerContract.omit([
  'urlaAlternateNames',
  'residences',
  'employment',
]);

// borrower contract for the coborrower applications
export const MinimalBorrowerContract = BorrowerContract.pick([
  'id',
  'firstName',
  'lastName',
  'emailAddressText',
]).shape({
  emailAddressText: y.string().optional(),
});

// coborrower (spouse) contract for the coborrower applications
export const MinimalCoborrowerContract = MinimalBorrowerContract.shape({
  firstName: y.string().optional(),
  lastName: y.string().optional(),
});

export interface Borrower extends y.Asserts<typeof BorrowerContract> {}
export interface StoreBorrower
  extends y.Asserts<typeof StoreBorrowerContract> {}

export const borrowerNewValidationErrorMap = {
  _entityName: 'Borrower',
  borrower: {
    contact: {
      currentApplication: {
        borrower: {
          firstName: 'Please enter your first name',
          lastName: 'Please enter your last name',
          emailAddressText: 'Please enter your email address',
          homePhoneNumber: 'Please enter your phone number',
        },
      },
    },
    'personal-details': {
      currentApplication: {
        borrower: {
          birthDate: 'Please select a valid birth date',
          maritalStatusType: 'Please select your marital status',
          schoolingTermYears: 'Please select your education attainment',
        },
      },
    },
    residency: {
      currentApplication: {
        borrower: {
          urla2020CitizenshipResidencyType:
            'Please select your residency status',
        },
      },
    },
    'home-address': {
      currentApplication: {
        borrower: {
          twoYearsMinimum: 'At least 2 years of residency are required',
        },
      },
    },
    'mailing-address': {
      currentApplication: {
        borrower: {
          mailingAddress: {
            missing: 'Please enter your mailing address',
            addressStreetLine1: 'Please enter your mailing street address',
            addressCity: 'Please enter your mailing address city',
            addressState: 'Please select your mailing address state',
            addressPostalCode: 'Please enter your mailing address postal code',
          },
        },
      },
    },
  },
  'assets-liabilities': {
    'other-ownership': {
      currentApplication: {
        borrower: {
          homeownerPastThreeYearsIndicator:
            "Please select whether you've had an ownership interest in any other properties in the last three years",
        },
      },
    },
    'other-ownership-use': {
      currentApplication: {
        borrower: {
          priorPropertyUsageType:
            'Please select how you used the properties that you owned',
        },
      },
    },
    'other-ownership-title': {
      currentApplication: {
        borrower: {
          priorPropertyTitleType:
            'Please select how you held title for the properties you previously owned',
        },
      },
    },
  },
  declarations: {
    ethnicity: {
      currentApplication: {
        borrower: {
          hmdaOtherHispanicLatinoOrigin: 'Please provide your origin',
        },
      },
    },
    race: {
      currentApplication: {
        borrower: {
          hmdaAmericanIndianTribe:
            'Please provide name of enrolled or principal tribe',
          hmdaOtherAsianRace: 'Please provide other asian race',
          hmdaOtherPacificIslanderRace:
            'Please provide other pacific islander race',
        },
      },
    },
    'language-preference': {
      currentApplication: {
        borrower: {
          languageCodeOtherDescription: 'Please enter your language preference',
        },
      },
    },
    social: {
      currentApplication: {
        borrower: {
          taxIdentificationIdentifier:
            'Please enter your Social Security Number',
        },
      },
    },
  },
};

export const coborrowerNewValidationErrorMap = {
  _entityName: 'CoBorrower',
  coborrower: {
    contact: {
      currentApplication: {
        coBorrower: {
          firstName: "Please enter your coborrower's first name",
          lastName: "Please enter your coborrower's last name",
          emailAddressText: "Please enter your coborrower's email address",
          homePhoneNumber: "Please enter your coborrower's phone number",
        },
      },
    },
    'personal-details': {
      currentApplication: {
        coBorrower: {
          birthDate: 'Please select a valid birth date for your coborrower',
          maritalStatusType: "Please select your coborrower's marital status",
          schoolingTermYears:
            "Please select your coborrower's education attainment",
        },
      },
    },
    residency: {
      currentApplication: {
        coBorrower: {
          urla2020CitizenshipResidencyType:
            "Please select your coborrower's residency status",
        },
      },
    },
    'home-address': {
      currentApplication: {
        coBorrower: {
          twoYearsMinimum: 'At least 2 years of residency are required',
        },
      },
    },
    'mailing-address': {
      currentApplication: {
        coBorrower: {
          mailingAddress: {
            missing: "Please enter your coborrower's mailing address",
            addressStreetLine1:
              "Please enter your coborrower's mailing street address",
            addressCity: "Please enter your coborrower's mailing address city",
            addressState:
              "Please select your coborrower's mailing address state",
            addressPostalCode:
              "Please enter your coborrower's mailing address postal code",
          },
        },
      },
    },
  },
  'assets-liabilities': {
    'coborrower-other-ownership': {
      currentApplication: {
        coBorrower: {
          homeownerPastThreeYearsIndicator:
            'Please select whether your coborrower had an ownership interest in any other properties in the last three years',
        },
      },
    },
    'coborrower-other-ownership-use': {
      currentApplication: {
        coBorrower: {
          priorPropertyUsageType:
            'Please select how your coborrower used the properties that they owned',
        },
      },
    },
    'coborrower-other-ownership-title': {
      currentApplication: {
        coBorrower: {
          priorPropertyTitleType:
            'Please select how your coborrower held title for the properties they previously owned',
        },
      },
    },
  },
  declarations: {
    ethnicity: {
      currentApplication: {
        coBorrower: {
          hmdaOtherHispanicLatinoOrigin:
            "Please provide your coborrower's origin",
        },
      },
    },
    race: {
      currentApplication: {
        coBorrower: {
          hmdaAmericanIndianTribe:
            "Please provide name of your coborrower's enrolled or principal tribe",
          hmdaOtherAsianRace:
            "Please provide your coborrower's other asian race",
          hmdaOtherPacificIslanderRace:
            "Please provide your coborrower's other pacific islander race",
        },
      },
    },
    'language-preference': {
      currentApplication: {
        coBorrower: {
          languageCodeOtherDescription:
            "Please enter your coborrower's language preference",
        },
      },
    },
    social: {
      currentApplication: {
        coBorrower: {
          taxIdentificationIdentifier:
            "Please enter your coborrower's Social Security Number",
        },
      },
    },
  },
};
