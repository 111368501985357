import * as y from 'yup';
import { BorrowerType } from 'src/types';

enum LiabilityOrExpenseType {
  Alimony = 'Alimony',
  ChildSupport = 'ChildSupport',
  JobRelatedExpenses = 'JobRelatedExpenses',
  Other = 'Other',
  SeparateMaintenanceExpense = 'SeparateMaintenanceExpense',
}

export const OtherLiabilityContract = y.object({
  id: y.string().uuid(),
  // entity with holder
  holderAddressCity: y.string().optional(),
  holderAddressPostalCode: y.string().optional(),
  holderAddressState: y.string().optional(),
  holderAddressStreetLine1: y.string().optional(),
  holderComments: y.string().optional(),
  holderEmail: y.string().optional(),
  holderFax: y.string().optional(),
  holderName: y.string().optional(),
  holderPhone: y.string().optional(),
  attention: y.string().optional(),
  // entity with title
  title: y.string().optional(),
  titlePhone: y.string().optional(),
  titleFax: y.string().optional(),
  // entity
  borrowerType: y.mixed().oneOf(Object.values(BorrowerType)),
  liabilityOrExpenseType: y
    .mixed()
    .oneOf(Object.values(LiabilityOrExpenseType))
    .required(),
  monthlyPayment: y.number().required(),
  monthsLeft: y.number().required(),
  otherDescription: y.string().optional().when('liabilityOrExpenseType', {
    is: LiabilityOrExpenseType.Other,
    then: y.string().required(),
  }),
  printUserNameIndicator: y.boolean().optional(),
  printAttachmentIndicator: y.boolean().optional(),
  depositoryRequestDate: y.string().optional(),
  printUserJobTitleIndicator: y.boolean().optional(),
  balance: y.number().optional(),
  creditLimit: y.number().optional(),
  foreignAddressIndicator: y.boolean().optional(),
  country: y.string().optional(),
});

export interface OtherLiability
  extends y.Asserts<typeof OtherLiabilityContract> {}

export enum OtherLiabilityActionType {
  SET = 'set_otherliability',
  ADD = 'add_otherliability',
  UPDATE = 'update_otherliability',
  DELETE = 'delete_otherliability',
}

export const otherLiabilityNewValidationErrorMap = {
  _entityName: 'OtherLiability',
  'assets-liabilities': {
    'other-payments': {
      currentApplication: {
        otherLiabilities: {
          '{0}': {
            liabilityOrExpenseType: 'Please select the other payment type',
            monthlyPayment: 'Please enter the other payment amount',
            otherDescription: 'Please enter the other payment description',
            monthsLeft:
              'Please enter the number of months left on your other payment',
          },
        },
      },
    },
  },
};

export const coborrowerOtherLiabilityNewValidationErrorMap = {
  _entityName: 'OtherLiability',
  'assets-liabilities': {
    'coborrower-other-payments': {
      currentApplication: {
        otherLiabilities: {
          '{0}': {
            liabilityOrExpenseType:
              "Please select your coborrower's other payment type",
            monthlyPayment:
              "Please enter your coborrower's other payment amount",
            otherDescription: 'Please enter the other payment description',
            monthsLeft:
              "Please enter the number of months left on your coborrower's other payment",
          },
        },
      },
    },
  },
};
