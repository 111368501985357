import * as y from 'yup';
import {
  useAlternateNames,
  useAssets,
  useResidences,
  useEmployments,
  useGiftsGrants,
  useLiabilities,
  useLoan,
  useLoanFields,
  useOtherAssets,
  useOtherIncomes,
  useOtherLiabilities,
  useReoProperties,
  useApplications,
} from 'src/hooks/use-selectors';
import {
  BorrowerOrCoBorrowerType,
  ContractErrorMap,
  SectionError,
  IssuePathRecord,
} from 'src/types';
import {
  alternateNameNewValidationErrorMap,
  coborrowerAlternateNameNewValidationErrorMap,
} from 'src/components/Loan/Application/Sections/BorrowerInfo/Inputs/AlternateNames/store/types';
import {
  borrowerNewValidationErrorMap,
  coborrowerNewValidationErrorMap,
} from 'src/types/borrower';
import {
  borrowerResidenceNewValidationErrorMap,
  coborrowerResidenceNewValidationErrorMap,
} from 'src/components/Loan/Application/Sections/BorrowerInfo/Inputs/Residence/store/types';
import {
  loanNewValidationErrorMap,
  ValidationLoan,
  ValidationLoanContract,
} from 'src/types/loan';
import { loanProductDataNewValidationErrorMap } from 'src/types/loanproductdata';
import { propertyNewValidationErrorMap } from 'src/types/property';
import { cloneDeep, fieldIds } from 'src/util';
import {
  employmentNewValidationErrorMap,
  coborrowerEmploymentNewValidationErrorMap,
} from 'src/components/Loan/Application/Sections/EmploymentInfo/Inputs/Employments/store/types';
import {
  assetNewValidationErrorMap,
  coborrowerNewAssetValidationErrorMap,
} from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Assets/store/types';
import {
  giftGrantNewValidationErrorMap,
  coborrowerGiftGrantNewValidationErrorMap,
} from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/GiftsGrants/store/types';
import {
  otherAssetNewValidationErrorMap,
  coborrowerOtherAssetNewValidationErrorMap,
} from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/OtherAssets/store/types';
import {
  liabilityNewValidationErrorMap,
  coborrowerLiabilityNewValidationErrorMap,
} from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Liabilities/store/types';
import {
  otherLiabilityNewValidationErrorMap,
  coborrowerOtherLiabilityNewValidationErrorMap,
} from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/OtherLiabilities/store/types';
import {
  reoPropertyNewValidationErrorMap,
  coborrowerReoPropertyNewValidationErrorMap,
} from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Properties/store/types';
import { useMemo } from 'react';
import {
  otherIncomeNewValidationErrorMap,
  coborrowerOtherIncomeNewValidationErrorMap,
} from 'src/components/Loan/Application/Sections/IncomeInfo/Inputs/OtherIncomes/store/types';
import { Application } from 'src/types/application';
import { useIsPrimaryBorrower } from 'src/hooks';

const errorMapCache = new WeakMap<
  ContractErrorMap,
  { [key: string]: SectionError }
>();

const buildIssuePaths = (
  tree: IssuePathRecord,
  currentPath = '',
): { key: string; message: string }[] => {
  const results: { key: string; message: string }[] = [];
  for (const key in tree) {
    const newPath = `${currentPath} ${key}`.trim().replace(' ', '.');
    if (typeof tree[key] === 'string') {
      results.push({
        key: newPath,
        message: tree[key] as string,
      });
    } else {
      const nested = buildIssuePaths(tree[key] as IssuePathRecord, newPath);
      results.push(...nested);
    }
  }
  return results;
};

const getErrorMapValues = (
  map: ContractErrorMap,
): { [key: string]: SectionError } => {
  if (errorMapCache.has(map)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return errorMapCache.get(map)!;
  }
  const res = {};
  const entityName = map._entityName;
  for (const section in map) {
    if (typeof map[section] === 'string') continue; // entity name case
    for (const page in map[section] as { [key: string]: IssuePathRecord }) {
      const issuePaths = buildIssuePaths(map[section][page]);
      for (const path of issuePaths) {
        res[path.key] = {
          entityName,
          section,
          page,
          route: `${section}/${page}`,
          key: path.key,
          message: path.message,
        };
      }
    }
  }
  errorMapCache.set(map, res);
  return res;
};

export const useValidationMaps = (
  hasCoborrower: boolean,
  sameAddressAsBorrower: boolean,
  isPrimaryApplicant: boolean,
) => {
  return useMemo(() => {
    const maps = [
      isPrimaryApplicant && loanNewValidationErrorMap,
      isPrimaryApplicant && propertyNewValidationErrorMap,
      loanProductDataNewValidationErrorMap,
      borrowerNewValidationErrorMap,
      hasCoborrower && coborrowerNewValidationErrorMap,
      alternateNameNewValidationErrorMap,
      hasCoborrower && coborrowerAlternateNameNewValidationErrorMap,
      borrowerResidenceNewValidationErrorMap,
      hasCoborrower &&
        !sameAddressAsBorrower &&
        coborrowerResidenceNewValidationErrorMap,
      employmentNewValidationErrorMap,
      hasCoborrower && coborrowerEmploymentNewValidationErrorMap,
      assetNewValidationErrorMap,
      hasCoborrower && coborrowerNewAssetValidationErrorMap,
      giftGrantNewValidationErrorMap,
      hasCoborrower && coborrowerGiftGrantNewValidationErrorMap,
      otherAssetNewValidationErrorMap,
      hasCoborrower && coborrowerOtherAssetNewValidationErrorMap,
      liabilityNewValidationErrorMap,
      hasCoborrower && coborrowerLiabilityNewValidationErrorMap,
      otherLiabilityNewValidationErrorMap,
      hasCoborrower && coborrowerOtherLiabilityNewValidationErrorMap,
      reoPropertyNewValidationErrorMap,
      hasCoborrower && coborrowerReoPropertyNewValidationErrorMap,
      otherIncomeNewValidationErrorMap,
      hasCoborrower && coborrowerOtherIncomeNewValidationErrorMap,
    ];
    const res: SectionError[] = [];
    for (const map of maps) {
      if (map) res.push(...Object.values(getErrorMapValues(map)));
    }
    return res;
  }, [hasCoborrower, sameAddressAsBorrower, isPrimaryApplicant]);
};

/**
 * Get the rebuilt loan entity from all the separate stores
 */
const useRebuiltLoan = (): ValidationLoan | undefined => {
  const loan = useLoan() as ValidationLoan;
  const applications = useApplications() as Application[];
  const customFields = useLoanFields();
  const giftsGrants = useGiftsGrants();
  const assets = useAssets();
  const liabilities = useLiabilities();
  const otherAssets = useOtherAssets();
  const otherLiabilities = useOtherLiabilities();
  const otherIncomes = useOtherIncomes();
  const reoProperties = useReoProperties();
  const employmentsBorrower = useEmployments(BorrowerOrCoBorrowerType.Borrower);
  const alternateNamesBorrower = useAlternateNames(
    BorrowerOrCoBorrowerType.Borrower,
  );
  const residencesBorrower = useResidences(BorrowerOrCoBorrowerType.Borrower);
  const employmentsCoborrower = useEmployments(
    BorrowerOrCoBorrowerType.CoBorrower,
  );
  const alternateNamesCoborrower = useAlternateNames(
    BorrowerOrCoBorrowerType.CoBorrower,
  );
  const residencesCoborrower = useResidences(
    BorrowerOrCoBorrowerType.CoBorrower,
  );

  return useMemo(() => {
    if (!loan || !Object.keys(loan).length || !applications.length) return;
    const loanCopy = cloneDeep(loan);
    // @ts-ignore
    loanCopy.applications = cloneDeep(applications);
    // @ts-ignore
    loanCopy.currentApplication = loanCopy.applications[0];
    loanCopy.customFields = cloneDeep(customFields);
    loanCopy.currentApplication.giftsGrants = cloneDeep(giftsGrants);
    loanCopy.currentApplication.vods = cloneDeep(assets);
    loanCopy.currentApplication.vols = cloneDeep(liabilities);
    loanCopy.currentApplication.otherAssets = cloneDeep(otherAssets);
    loanCopy.currentApplication.otherLiabilities = cloneDeep(otherLiabilities);
    loanCopy.currentApplication.otherIncomeSources = cloneDeep(otherIncomes);
    loanCopy.currentApplication.reoProperties = cloneDeep(reoProperties);
    loanCopy.currentApplication.borrower.employment =
      cloneDeep(employmentsBorrower);
    loanCopy.currentApplication.borrower.urlaAlternateNames = cloneDeep(
      alternateNamesBorrower,
    );
    loanCopy.currentApplication.borrower.residences =
      cloneDeep(residencesBorrower);
    loanCopy.currentApplication.coBorrower.employment = cloneDeep(
      employmentsCoborrower,
    );
    loanCopy.currentApplication.coBorrower.urlaAlternateNames = cloneDeep(
      alternateNamesCoborrower,
    );
    loanCopy.currentApplication.coBorrower.residences =
      cloneDeep(residencesCoborrower);
    return loanCopy;
  }, [
    loan,
    applications,
    customFields,
    giftsGrants,
    assets,
    liabilities,
    otherAssets,
    otherLiabilities,
    reoProperties,
    employmentsBorrower,
    alternateNamesBorrower,
    residencesBorrower,
    employmentsCoborrower,
    alternateNamesCoborrower,
    residencesCoborrower,
    otherIncomes,
  ]);
};

const pathSegmentMatches = (pathSection: string, keySection: string) => {
  if (/^\{\d+\}$/.test(keySection)) {
    // console.log('checking', keySection, pathSection);
    return /^\d+$/.test(pathSection);
  } else {
    return keySection === pathSection.toString();
  }
};

const pathMatches = (path: string, key: string) => {
  const keyParts = key.split('.');
  const pathParts = path.split('.');
  if (keyParts.length !== pathParts.length) return false;
  // console.log('matches?', pathParts, keyParts);
  for (let i = 0; i < pathParts.length; ++i) {
    if (!pathSegmentMatches(pathParts[i], keyParts[i])) return false;
  }
  return true;
};

const getRelevantErrorMaps = (
  issues: y.ValidationError[],
  maps: SectionError[],
) => {
  const res: SectionError[] = [];
  for (const issue of issues) {
    const matchedMap = maps.find((map) =>
      pathMatches(issue.path || '', map.key),
    );
    if (matchedMap) {
      res.push({
        ...matchedMap,
        path: issue.path,
      });
    }
  }
  return res;
};

const getEntityIssues = (
  contract: y.BaseSchema,
  entity: Record<string, unknown>,
  maps: SectionError[],
  loan: ValidationLoan,
): SectionError[] => {
  try {
    if (!loan) return [];
    contract.validateSync(entity, {
      abortEarly: false,
      context: {
        loan,
      },
    });
    return [];
  } catch (e) {
    if (e instanceof y.ValidationError) {
      const parsedIssues = e.inner?.map((error) => {
        let keyType = error.type?.startsWith('key:')
          ? error.type.substr(4)
          : '';
        if (error.type === 'min') keyType = error.type;
        if (keyType) {
          if (error.type?.startsWith('key:')) {
            const originalPath = error.path?.substring(
              0,
              error.path.lastIndexOf('.'),
            );
            error.path =
              originalPath + (originalPath?.length ? '.' : '') + keyType;
          } else {
            error.path = error.path + (error.path ? '.' : '') + keyType;
          }
        }
        return {
          ...error,
          path: error.path?.replace(/\[(\d+)\]/, '.$1'),
        };
      });
      // console.log(maps);
      return getRelevantErrorMaps(parsedIssues, maps);
    } else {
      throw e;
    }
  }
};

export const useLoanValidation = () => {
  const rebuilt = useRebuiltLoan();
  const fields = useLoanFields();
  const isPrimaryApplicant = useIsPrimaryBorrower();
  const hasCoborrower = fields[fieldIds.hasCoborrower] === 'true';
  const sameAddressAsBorrower = fields[fieldIds.sameAddressAsBorrower] === 'Y';
  const maps = useValidationMaps(
    hasCoborrower,
    sameAddressAsBorrower,
    isPrimaryApplicant,
  );

  return useMemo(() => {
    if (!rebuilt) return [];

    const matchedIssues = getEntityIssues(
      ValidationLoanContract,
      rebuilt,
      maps,
      rebuilt,
    );

    // console.log(matchedIssues)
    return matchedIssues;
  }, [maps, rebuilt]);
};
