export const FORM_DEBOUNCE_DELAY = 1200;
export const FORM_DEBOUNCE_DELAY_SHORT = 200;
export const DATE_FORMAT_STRING = "MM/dd/yy 'at' h:mm a";

export const FEATURE_FLAGS = {
  useEconsent: 'cinch-borrower-use-econsent',
  useEmailVerification: 'cinch-email-verification',
};

export const IMAGETYPE_JPEG = 'image/jpeg';

export const LIABILITY_MAX_REMAINING_MONTHS = 3000;
