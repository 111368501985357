import {
  ReoProperty,
  ReoPropertyActionType,
} from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Properties/store/types';

type ReoPropertyStore = ReoProperty[];

const defaultState: ReoPropertyStore = [];

const reducer = (
  state: ReoPropertyStore = defaultState,
  { type, data }: { type: ReoPropertyActionType; data: any },
): ReoPropertyStore => {
  switch (type) {
    // @ts-ignore
    case 'clear_loan_data':
      return defaultState;
    case ReoPropertyActionType.SET: {
      const items = [...state];
      for (const item of data) {
        const matchIndex = state.findIndex((x) => x.id === item.id);
        if (matchIndex > -1) {
          items.splice(matchIndex, 1, {
            ...items[matchIndex],
            ...item,
          });
        } else {
          items.push(item);
        }
      }
      return items;
    }
    case ReoPropertyActionType.UPDATE: {
      const matchIndex = state.findIndex((x) => x.id === data.id);
      const items = [...state];

      items.splice(matchIndex, 1, {
        ...items[matchIndex],
        ...data,
      });
      return items;
    }
    case ReoPropertyActionType.ADD: {
      const items = [...state, data];
      return items;
    }
    case ReoPropertyActionType.DELETE: {
      const matchIndex = state.findIndex((x) => x.id === data);
      const items = [...state];
      items.splice(matchIndex, 1);
      return items;
    }
    default:
      return state;
  }
};

export default reducer;
