import { ApplicationActionType } from 'src/components/Loan/Application/store/actionTypes';
import { Application } from 'src/types/application';
import { mergeDeep } from 'src/util';

const defaultState: Application[] = [];

const reducer = (
  state = defaultState,
  { type, data }: { type: ApplicationActionType; data: any },
): Application[] => {
  switch (type) {
    case ApplicationActionType.SET: {
      return data;
    }
    case ApplicationActionType.UPDATE: {
      const matchIndex = state.findIndex((x) => x.id === data.id);
      const items = [...state];

      items.splice(matchIndex, 1, mergeDeep(items[matchIndex], data));
      return items;
    }
    case ApplicationActionType.ADD: {
      const items = [...state, data];
      return items;
    }
    // @ts-ignore
    case 'clear_loan_data': {
      return defaultState;
    }
    default:
      return state;
  }
};

export default reducer;
