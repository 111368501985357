import { apiAxios } from 'src/util';
import { workstate } from './';

export const verifyBorrowerEmail = async (email, verificationCode) => {
  const res = await apiAxios.post(`users/verify/${email}/${verificationCode}`);
  return res.data;
};

export const registerNewBorrower = (data) => (dispatch) => {
  return workstate(dispatch, 'register', async () => {
    try {
      return await apiAxios.post('users/register', data);
    } catch (e) {
      return e;
    }
  });
};

export const registerNewBorrowerOnLoan = (data, loanGuid) => (dispatch) => {
  return workstate(dispatch, 'register', async () => {
    try {
      return await apiAxios.post(`users/register/${loanGuid}`, data);
    } catch (e) {
      return e;
    }
  });
};

export const resendEmailVerification = (email) => (dispatch) => {
  return workstate(dispatch, 'resend-verification-email', () => {
    return apiAxios.post(`users/resend-verification-email/${email}`);
  });
};
